'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../ActionTypes';
import * as Domains from '../../api/domains/Domains';
import { DomainConnectChangeTypes } from '../../Constants';
import { getApplyTemplateFailedNotification, getApplyTemplateFailedWithCustomMessageNotification } from '../../utils/domains/langUtils';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { verifyTaskEmailDomain } from './EmailSendingDomains';
import { fetchTaskDomainsVerifyInfo } from './FetchTaskDomainsVerifyInfo';
const getErrorResult = error => {
  if (error && error.responseJSON) {
    return error.responseJSON.result;
  }
  return undefined;
};
export function getApplyDomainTemplateAttemptedAction() {
  return {
    type: ActionTypes.APPLY_DOMAIN_TEMPLATE_ATTEMPTED
  };
}
export function getApplyDomainTemplateSucceededAction(domainConnectChangeType) {
  return {
    type: ActionTypes.APPLY_DOMAIN_TEMPLATE_SUCCEEDED,
    domainConnectChangeType
  };
}
export function getApplyDomainTemplateFailedAction() {
  return {
    type: ActionTypes.APPLY_DOMAIN_TEMPLATE_FAILED
  };
}
export function applyContentHostingDomainConnectTemplate(domainIds, taskId, inPurchaseModal = false, changeTypesMap) {
  return dispatch => {
    dispatch(getApplyDomainTemplateAttemptedAction());
    return Promise.all(domainIds.toJS().map(domainId => Domains.applyDomainConnectTemplate(domainId, changeTypesMap.get(domainId)))).then(() => {
      dispatch(getApplyDomainTemplateSucceededAction());
      dispatch(fetchTaskDomainsVerifyInfo(taskId));
    }, error => {
      dispatch(getApplyDomainTemplateFailedAction());
      if (getErrorResult(error) === 'FAILURE_ZONE_EXPIRED') {
        FloatingAlertStore.addAlert(getApplyTemplateFailedWithCustomMessageNotification(domainIds.size));
      } else if (!inPurchaseModal) {
        FloatingAlertStore.addAlert(getApplyTemplateFailedNotification(domainIds.size));
      }
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}

// Used after GoDaddy Purchase Domain Modal completes
// For now, we only support purchasing GoDaddy www subdomains
export function afterDelayApplyContentHostingDomainConnectTemplate(domainIds, taskId, dispatch) {
  dispatch(getApplyDomainTemplateAttemptedAction());
  setTimeout(() => dispatch(applyContentHostingDomainConnectTemplate(domainIds, taskId, true, Immutable.Map(domainIds.map(domainId => [domainId, DomainConnectChangeTypes.SUBDOMAIN])))), 120000);
}
export function applyEmailDomainConnectTemplate(domainName) {
  return dispatch => {
    dispatch(getApplyDomainTemplateAttemptedAction());
    Domains.applyDomainConnectTemplate(domainName, DomainConnectChangeTypes.DKIM).then(() => {
      dispatch(getApplyDomainTemplateSucceededAction());
      dispatch(verifyTaskEmailDomain(domainName));
    }, () => {
      dispatch(getApplyDomainTemplateFailedAction());
      FloatingAlertStore.addAlert(getApplyTemplateFailedNotification(1));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function applyPreProvisionDomainConnectTemplate(domainIds, verifyDnsRecords) {
  return dispatch => {
    dispatch(getApplyDomainTemplateAttemptedAction());
    Promise.all(domainIds.toJS().map(domainId => Domains.applyDomainConnectTemplate(domainId, DomainConnectChangeTypes.PREPROVISIONING))).then(() => {
      dispatch(getApplyDomainTemplateSucceededAction(DomainConnectChangeTypes.PREPROVISIONING));
      verifyDnsRecords();
    }, () => {
      dispatch(getApplyDomainTemplateFailedAction());
      FloatingAlertStore.addAlert(getApplyTemplateFailedNotification(domainIds.count()));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function applyOwnershipVerificationDomainConnectTemplate(domainIds) {
  return dispatch => {
    dispatch(getApplyDomainTemplateAttemptedAction());
    Promise.all(domainIds.toJS().map(domainId => Domains.applyDomainConnectTemplate(domainId, DomainConnectChangeTypes.OWNERSHIP_VERIFICATION))).then(() => {
      dispatch(getApplyDomainTemplateSucceededAction());
    }, () => {
      dispatch(getApplyDomainTemplateFailedAction());
      FloatingAlertStore.addAlert(getApplyTemplateFailedNotification(domainIds.size));
    }).catch(err => {
      throw err;
    });
  };
}