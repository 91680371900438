'use es6';

import * as BrandNameErrorType from '../../../enums/domains/BrandNameErrorType';
function getI18nKey(suffix) {
  return `settings-ui-domains.brandNameValidationMessage.${suffix}`;
}
export default function getBrandNameValidationMessageKey(errorType) {
  if (BrandNameErrorType[errorType]) {
    return getI18nKey(BrandNameErrorType[errorType]);
  }
  return null;
}