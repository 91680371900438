import { AutomaticRedirectApexTracking } from '../../Constants';
import { ValidationState, PreferredDefaultAutomaticRedirectSubdomains } from '../../Constants';
// @ts-expect-error not migrated yet
import { validateDomain } from '../../api/domains/Domains';
import UsageTracker from '../usageTracker';
import getDomainValidationErrorList from './getDomainValidationErrorList';
import { orderDomainUsageTypes } from './UsageTypes';
export function findHighestPriorityValidDomainUsageType(givenDomainUsageTypes, subdomainNames) {
  const ordededDomainUsageTypes = orderDomainUsageTypes(givenDomainUsageTypes);
  return ordededDomainUsageTypes.find(domainUsageType => {
    const subdomain = subdomainNames[domainUsageType];
    return PreferredDefaultAutomaticRedirectSubdomains.some(automaticRedirectSubdomain => automaticRedirectSubdomain === subdomain);
  });
}
export function validateRedirectDomain({
  redirectSubdomain,
  baseRedirectDomain,
  subdomainNames
}) {
  return new Promise(resolve => {
    if (Object.values(subdomainNames).includes(redirectSubdomain)) {
      resolve({
        isValid: false,
        validationErrors: [ValidationState.CLAIMED_BY_SETUP_DOMAINS]
      });
      return;
    }
    const fullRedirectDomain = redirectSubdomain ? `${redirectSubdomain}.${baseRedirectDomain}` : baseRedirectDomain;
    validateDomain(fullRedirectDomain).then(() => {
      resolve({
        isValid: true,
        validationErrors: []
      });
    }).catch(errorResponse => {
      const validationErrors = getDomainValidationErrorList(errorResponse.responseJSON);
      resolve({
        isValid: false,
        validationErrors
      });
    });
  });
}
export async function calulateDefaultRedirect(baseRedirectDomain, subdomainNames) {
  for (const subdomain of PreferredDefaultAutomaticRedirectSubdomains) {
    const validation = await validateRedirectDomain({
      redirectSubdomain: subdomain,
      baseRedirectDomain,
      subdomainNames
    });
    if (validation.isValid) {
      return {
        subdomain,
        enabled: true
      };
    }
  }
  return {
    subdomain: '',
    enabled: false
  };
}
export async function calculateInitializeAutomaticRedirectPayload({
  setupDomainUsageTypes,
  suffixes,
  brandName,
  subdomainNames
}) {
  const targetedDomainUsageType = findHighestPriorityValidDomainUsageType(setupDomainUsageTypes, subdomainNames);
  if (!targetedDomainUsageType) {
    return {
      isValidSetup: false
    };
  }
  const targetedSuffix = suffixes[targetedDomainUsageType];
  const baseRedirectDomain = `${brandName}.${targetedSuffix}`;
  const defaultRedirect = await calulateDefaultRedirect(baseRedirectDomain, subdomainNames);
  const {
    subdomain,
    enabled
  } = defaultRedirect;
  if (!enabled) {
    return {
      isValidSetup: false
    };
  }
  return {
    isValidSetup: true,
    subdomain,
    enabled,
    targetedDomainUsageType
  };
}
export const calulateAutomaticRedirectBaseDomain = (automaticRedirect, suffixes, brandName) => {
  const {
    targetedDomainUsageType
  } = automaticRedirect;
  if (targetedDomainUsageType) {
    const targetedSuffix = suffixes[targetedDomainUsageType];
    return `${brandName}.${targetedSuffix}`;
  }
  return '';
};
export const calulateAutomaticRedirectDomain = (automaticRedirect, baseRedirectDomain) => {
  const {
    targetedDomainUsageType,
    subdomain,
    enabled
  } = automaticRedirect;
  let automaticRedirectDomain = '';
  if (enabled && targetedDomainUsageType) {
    automaticRedirectDomain = subdomain ? `${subdomain}.${baseRedirectDomain}` : baseRedirectDomain;
  }
  return automaticRedirectDomain;
};
export const calulateAutomaticRedirectDestination = (automaticRedirect, suffixes, brandName, subdomainNames) => {
  const {
    targetedDomainUsageType,
    enabled
  } = automaticRedirect;
  let automaticRedirectDestination = '';
  if (enabled && targetedDomainUsageType) {
    const targetedSubdomain = subdomainNames[targetedDomainUsageType];
    const targetedSuffix = suffixes[targetedDomainUsageType];
    const baseDestinationDomain = `${brandName}.${targetedSuffix}`;
    automaticRedirectDestination = targetedSubdomain ? `${targetedSubdomain}.${baseDestinationDomain}` : baseDestinationDomain;
  }
  return automaticRedirectDestination;
};
export const calculateTrackingSubdomainValue = (enabled, subdomain) => {
  let trackingValue = undefined;
  if (enabled) {
    if (subdomain === '') {
      trackingValue = AutomaticRedirectApexTracking;
    } else {
      trackingValue = subdomain;
    }
  }
  return trackingValue;
};
export const trackUpdateAutomaticRedirect = ({
  existingEnabled,
  enabled,
  subdomain
}) => {
  const trackingSubdomainValue = calculateTrackingSubdomainValue(enabled, subdomain);
  let trackingEnabled = undefined;
  if (existingEnabled !== enabled) {
    trackingEnabled = enabled;
  }
  UsageTracker.track('updateAutomaticRedirect', {
    enabled: trackingEnabled,
    subdomain: trackingSubdomainValue
  });
};