//@ts-expect-error not migrated yet
import { getIsRedirectDomain } from './Domains';
import Immtable from 'immutable';
const getIsNotificationContinueSetupButtonEnabled = (domain, isEmailSendingDomain, userHasEmailSendingDomainsWriteScope, brandNamesList) => {
  if (isEmailSendingDomain) {
    return userHasEmailSendingDomainsWriteScope;
  }
  if (getIsRedirectDomain(Immtable.Map(Object.assign({}, domain)))) {
    return true;
  }
  if ('derivedBrandName' in domain) {
    return brandNamesList.includes(domain.derivedBrandName);
  }
  return false;
};
export default getIsNotificationContinueSetupButtonEnabled;