import Raven from 'raven-js';
import { verifyDomainSslAndReturnFirstIssue } from '../../api/domains/SslVerification';
import { SslVerificationActions } from '../ActionTypes';
export const verifyDomainSsl = domainName => {
  return async dispatch => {
    dispatch({
      type: SslVerificationActions.initialize
    });
    let sslVerificationData;
    try {
      sslVerificationData = await verifyDomainSslAndReturnFirstIssue(domainName);
      dispatch({
        type: SslVerificationActions.verificationSucceeded,
        sslIssue: sslVerificationData && sslVerificationData.sslIssue,
        isSslValid: sslVerificationData && sslVerificationData.isSslValid,
        domainName
      });
    } catch (error) {
      Raven.captureException(error, {
        tags: {
          action: SslVerificationActions.verificationFailed
        }
      });
      dispatch({
        domainName,
        type: SslVerificationActions.verificationFailed
      });
    }
  };
};