import { getI18nAppNotification } from './redirects/lang';
export const getSuccessGenericNotification = i18nPrefix => {
  return {
    message: getI18nAppNotification(`${i18nPrefix}.succeeded.message`),
    type: 'success'
  };
};
export const getErrorGenericNotification = (i18nPrefix, options) => {
  return {
    message: getI18nAppNotification(`${i18nPrefix}.message`, options),
    titleText: getI18nAppNotification(`${i18nPrefix}.title`, options),
    type: 'danger'
  };
};
export const getFailureGenericNotification = (i18nPrefix, options) => {
  return getErrorGenericNotification(`${i18nPrefix}.failed`, options);
};