import keyMirror from 'react-utils/keyMirror';
export const DomainConnectErrors = keyMirror({
  CANCELED: null,
  FAILED: null
});
export const ErrorDescriptions = {
  CANCELED: 'user_cancel'
};
export const DomainConnectMessages = keyMirror({
  SUCCEEDED: null,
  FAILED: null
});
export const ConnectTypes = keyMirror({
  HS_DOMAIN_CONNECT_ASYNC: null
});
export const DnsProviders = keyMirror({
  GODADDY: null,
  ONE_AND_ONE: null
});
export const AccessErrors = ['access_denied', 'invalid_scope'];
export const SystemErrors = ['temporarilly_unavailable', 'server_error'];
export const DomainConnectPartnershipErrors = ['unsupported_response_type', 'unauthorized_client', 'invalid_request'];