/* hs-eslint ignored failing-rules */

'use es6';

import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { FETCH_DOWNGRADE_NOTIFICATIONS_SUCCEEDED, FETCH_DOWNGRADE_NOTIFICATIONS_FAILED, DISMISS_DOWNGRADE_NOTIFICATION_SUCCEEDED } from '../ActionTypes';
import * as DowngradeNotificationsApi from '../../api/domains/DowngradeNotifications';
import { getFetchDowngradeNotificationsFailedNotification } from '../../utils/domains/langUtils';
import { getFailureGenericNotification } from '../../utils/notifications';
export const getFetchDowngradeNotificationsSucceededAction = downgrades => ({
  type: FETCH_DOWNGRADE_NOTIFICATIONS_SUCCEEDED,
  downgrades
});
const getFetchDowngradeNotificationsFailedAction = () => ({
  type: FETCH_DOWNGRADE_NOTIFICATIONS_FAILED
});
export const fetchDowngradeNotifications = () => dispatch => {
  DowngradeNotificationsApi.fetchDowngradeNotifications().then(downgradeNotifications => dispatch(getFetchDowngradeNotificationsSucceededAction(downgradeNotifications.get('downgrades'))), () => {
    dispatch(getFetchDowngradeNotificationsFailedAction());
    FloatingAlertStore.addAlert(getFetchDowngradeNotificationsFailedNotification());
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const getDismissDowngradeNotificationSucceededAction = downgradeId => ({
  type: DISMISS_DOWNGRADE_NOTIFICATION_SUCCEEDED,
  downgradeId
});
export const dismissDowngradeNotification = downgradeId => dispatch => {
  DowngradeNotificationsApi.dismissDowngradeNotification(downgradeId).then(() => dispatch(getDismissDowngradeNotificationSucceededAction(downgradeId)), () => {
    FloatingAlertStore.addAlert(getFailureGenericNotification('dismissDowngradeNotifications'));
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};