import { createSelector } from 'reselect';
import { getSubdomainNames, getDomainsSuffixes } from './DomainWizardTyped';
import { getBrandName } from './DomainWizard';
import { calulateAutomaticRedirectDomain, calulateAutomaticRedirectDestination, calulateAutomaticRedirectBaseDomain } from '../../utils/domains/automaticRedirect';
export function getAutomaticRedirect(state) {
  const automaticRedirectMap = state.domainWizard.get('automaticRedirect');
  return {
    isValidSetup: automaticRedirectMap.get('isValidSetup'),
    targetedDomainUsageType: automaticRedirectMap.get('targetedDomainUsageType'),
    subdomain: automaticRedirectMap.get('subdomain'),
    defaultSubdomain: automaticRedirectMap.get('defaultSubdomain'),
    enabled: automaticRedirectMap.get('enabled')
  };
}
export const getAutomaticRedirectBaseDomain = createSelector([getAutomaticRedirect, getDomainsSuffixes, getBrandName], calulateAutomaticRedirectBaseDomain);
export const getAutomaticRedirectDomain = createSelector([getAutomaticRedirect, getAutomaticRedirectBaseDomain], calulateAutomaticRedirectDomain);
export const getAutomaticRedirectDestination = createSelector([getAutomaticRedirect, getDomainsSuffixes, getBrandName, getSubdomainNames], calulateAutomaticRedirectDestination);