import { createSelector } from 'reselect';
import { ListingState, RequestStatus } from '../../Constants';
import { getIsPortalScopedForBusinessUnitsAccess } from '../Auth';
export const getBrandsRequestStatus = state => state.brands.get('brandsRequestStatus');
export const getAddBrandRequestStatus = state => state.brands.get('addBrandRequestStatus');
export const getDeleteBrandRequestStatus = state => state.brands.get('deleteBrandRequestStatus');
export const getUpdateBrandBusinessUnitRequestStatus = state => state.brands.get('updateBrandBusinessUnitRequestStatus');
export const getBrands = state => state.brands.get('brands');
export const getPortalBrandCount = state => state.brands.get('portalBrandCount');
export const getAllowedBrandCount = state => state.brands.get('allowedBrandCount');
export const getBrandNamesList = createSelector([getBrands], brands => brands.map(brandObj => brandObj.get('name')));
export const getIsBelowBrandsLimit = createSelector([getPortalBrandCount, getAllowedBrandCount, getBrandsRequestStatus], (portalBrandCount, allowedBrandCount, brandsRequestStatus) => brandsRequestStatus === RequestStatus.SUCCEEDED && portalBrandCount < allowedBrandCount);
export const getBrandDomainsListingState = createSelector([getBrands, getBrandsRequestStatus, getIsPortalScopedForBusinessUnitsAccess], (brandDomains, requestStatus, isPortalScopedForBusinessUnitsAccess) => {
  if (requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNINITIALIZED) {
    return ListingState.LOADING;
  } else if (requestStatus === RequestStatus.FAILED) {
    return ListingState.ERROR;
  }
  if (brandDomains.size === 0 || !isPortalScopedForBusinessUnitsAccess) {
    return ListingState.EMPTY;
  }
  return ListingState.RETRIEVED;
});