import I18n from 'I18n';
import { createRedirectUri } from 'domain-connect-lib/synchronousDomainConnect';
import { DomainConnectChangeTypes, RequestStatus } from '../../Constants';
import { fetchSyncUrl } from '../../api/domains/SynchronousDomainConnection';
import { getContentHostingChangeType, getDomainIdsPendingOwnershipValidation
//@ts-expect-error not migrated yet
} from './Domains';
//@ts-expect-error not migrated yet
import { getEmailSendingDomainPunycodeName } from '../../selectors/domains/EmailSendingDomains';
export function inferDomainConnectChangeTypes({
  addingEmailSendingDomain,
  emailDomainForHostingSetup,
  newContentHostingDomainIds,
  apexDomain,
  domains
}) {
  const changeTypes = {};
  if (addingEmailSendingDomain) {
    const emailSendingDomainPunycodeName = getEmailSendingDomainPunycodeName(emailDomainForHostingSetup);
    const changeType = DomainConnectChangeTypes.DKIM;
    changeTypes[`${emailSendingDomainPunycodeName}-${changeType}`] = {
      domainName: emailSendingDomainPunycodeName,
      changeType
    };
    return changeTypes;
  }
  const getDomainName = domainId => domains.get(domainId).get('domain');
  newContentHostingDomainIds.forEach(domainId => {
    const changeType = getContentHostingChangeType(domainId, apexDomain, domains);
    changeTypes[`${domainId}-${changeType}`] = {
      domainName: getDomainName(domainId),
      changeType
    };
  });
  const ownershipVerificationDomainIds = getDomainIdsPendingOwnershipValidation(domains, newContentHostingDomainIds);
  ownershipVerificationDomainIds.forEach(domainId => {
    const domainName = getDomainName(domainId);
    const changeType = DomainConnectChangeTypes.OWNERSHIP_VERIFICATION;
    changeTypes[`${domainId}-${changeType}`] = {
      domainName,
      changeType,
      presentationName: `${I18n.text('settings-ui-domains.synchronousDomainConnect.ownershipVerificationName')}: ${domainName}`
    };
  });
  return changeTypes;
}
export function buildPreProvisioningChangeTypes(taskDomainsSupportingSsl) {
  return taskDomainsSupportingSsl.reduce((acc, domain) => {
    const domainName = domain.get('domain');
    const domainId = domain.get('id');
    return Object.assign({}, acc, {
      [`${domainId}-${DomainConnectChangeTypes.PREPROVISIONING}`]: {
        domainName,
        changeType: DomainConnectChangeTypes.PREPROVISIONING,
        presentationName: `${I18n.text('settings-ui-domains.synchronousDomainConnect.preProvisionSslName')}: ${domainName}`
      }
    });
  }, {});
}
export function parseSyncFlowUrlFromNetworkRequest(data) {
  if (!data || !data[0]) {
    throw new Error('No data from sync-domain-connect-urls');
  }
  if (!data[0].syncFlowUrl) {
    throw new Error('No syncFlowUrl from sync-domain-connect-urls');
  }
  return data[0].syncFlowUrl;
}
export function bulkFetchSyncFlowUrls(changeTypes) {
  return Promise.all(Object.entries(changeTypes).map(([domainChangeId, {
    domainName,
    changeType
  }]) => {
    const redirectUri = createRedirectUri(domainChangeId);
    return fetchSyncUrl(domainName, changeType, redirectUri).then(parseSyncFlowUrlFromNetworkRequest).then(syncFlowUrl => ({
      domainChangeId,
      syncFlowUrl
    }));
  })).then(syncFlowUrlsList => syncFlowUrlsList.reduce((acc, {
    domainChangeId,
    syncFlowUrl
  }) => Object.assign({}, acc, {
    [domainChangeId]: Object.assign({}, changeTypes[domainChangeId], {
      syncFlowUrl,
      status: RequestStatus.UNINITIALIZED
    })
  }), {}));
}