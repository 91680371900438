'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { isDkimRecordValid } from './Domains';
import SpfCheckResultStatuses from '../../enums/domains/SpfCheckResultStatuses';
import DmarcCheckResultStatuses from '../../enums/domains/DmarcCheckResultStatuses';
import { DmarcStatusCheckDefaultValue, SpfRecordsStatusCheckDefaultValue } from '../../Constants';
export const normalizeEmailSendingDnsRecords = (dkimRecords, recordTypeSuffix = null) => {
  return List([ImmutableMap({
    conflictingRecords: List(),
    records: List(dkimRecords.map(record => {
      const actualValue = record.get('resolvedRecordValue');
      const dkimRecord = record.get('dkimRecord');
      const type = !recordTypeSuffix ? dkimRecord.get('recordType') : `${dkimRecord.get('recordType')} (${recordTypeSuffix})`;
      const host = dkimRecord.get('hostName');
      const expectedValue = dkimRecord.get('value');
      const isValid = isDkimRecordValid(record);
      return ImmutableMap({
        type,
        host,
        expectedValue,
        actualValue,
        isValid
      });
    }))
  })]);
};
export const normalizeSpfRecords = (spfRecords, recordTypeSuffix = null) => {
  return List([ImmutableMap({
    conflictingRecords: List(),
    records: List(spfRecords.map(record => {
      const actualValue = record.get('resolvedRecordValue');
      const spfInclude = record.get('spfInclude');
      const type = !recordTypeSuffix ? spfInclude.get('recordType') : `${spfInclude.get('recordType')} (${recordTypeSuffix})`;
      const host = spfInclude.get('hostName');
      const expectedValue = spfInclude.get('expectedSpfValue');
      const checkStatus = record.get('result') || SpfRecordsStatusCheckDefaultValue;
      const isValid = checkStatus.toUpperCase() === SpfCheckResultStatuses.SUCCESS;
      return ImmutableMap({
        type,
        host,
        expectedValue,
        actualValue,
        isValid,
        spfCheckStatus: checkStatus
      });
    }))
  })]);
};
export const getSpfCheckStatusFromNormalizedSpfRecords = spfRecords => {
  const spfRecordData = spfRecords.first().get('records');
  if (spfRecordData.first() !== undefined && spfRecordData.first().get('spfCheckStatus') !== undefined) {
    return spfRecordData.first().get('spfCheckStatus');
  }
  return SpfRecordsStatusCheckDefaultValue;
};
export const normalizeDmarcRecords = (dmarcRecords, recordTypeSuffix = null) => {
  return List([ImmutableMap({
    conflictingRecords: List(),
    records: List(dmarcRecords.map(record => {
      const actualValue = record.get('resolvedRecordValue');
      const dmarcRecord = record.get('expectedDmarcRecord');
      const type = !recordTypeSuffix ? dmarcRecord.get('recordType') : `${dmarcRecord.get('recordType')} (${recordTypeSuffix})`;
      const host = dmarcRecord.get('hostName');
      const expectedValue = dmarcRecord.get('value');
      let checkStatus = record.get('result') || DmarcStatusCheckDefaultValue;

      // override checks for initial iteration
      // relevant thread https://hubspot.slack.com/archives/C0661L3G1BK/p1702384565734649
      if ([DmarcCheckResultStatuses.STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF_AND_DKIM, DmarcCheckResultStatuses.STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_DKIM, DmarcCheckResultStatuses.STRICT_ALIGNMENT_ON_SUBDOMAIN_FOR_SPF].includes(checkStatus)) {
        checkStatus = DmarcCheckResultStatuses.SUCCESS;
      }
      const isValid = checkStatus.toUpperCase() === DmarcCheckResultStatuses.SUCCESS;
      return ImmutableMap({
        type,
        host,
        expectedValue,
        actualValue,
        isValid,
        dmarcCheckStatus: checkStatus
      });
    }))
  })]);
};
export const getDmarcCheckStatusFromNormalizedDmarcRecords = dmarcRecords => {
  const dmarcRecordData = dmarcRecords.first().get('records');
  if (dmarcRecordData.first() !== undefined && dmarcRecordData.first().get('dmarcCheckStatus') !== undefined) {
    return dmarcRecordData.first().get('dmarcCheckStatus');
  }
  return DmarcStatusCheckDefaultValue;
};