'use es6';

import * as DowngradeStatus from '../../enums/domains/DowngradeStatus';
export default function getDowngradeAlertType(downgrade) {
  switch (downgrade.get('downgradeStatus')) {
    case DowngradeStatus.DOWNGRADE_SUCCEEDED:
      return 'danger';
    default:
      return 'warning';
  }
}