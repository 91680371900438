const SSLSetupStatuses = Object.freeze({
  INITIALIZING: 0,
  PENDING_VALIDATION: 1,
  PENDING_ISSUANCE: 2,
  PENDING_DEPLOYMENT: 3,
  ACTIVE: 4,
  PENDING_DELETION: 5,
  DELETED: 6,
  NOT_CREATED: 7,
  UNKNOWN_STATE: 8,
  VALIDATION_TIMED_OUT: 9,
  EXPIRED: 10,
  PENDING_EXPIRATION: 11,
  ISSUANCE_TIMED_OUT: 12,
  INITIALIZING_TIMED_OUT: 13,
  PENDING_CLEANUP: 14,
  DEPLOYMENT_TIMED_OUT: 15
});
export default SSLSetupStatuses;