'use es6';

import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { RequestStatus, ListingState, DomainSetupTaskCategories, DomainUsageTypes, PageBuilderDomainUsageTypes } from '../../Constants';
import { isSecondaryDomain, getIsRedirectDomain, getIsDowngradedDomain, isContentHostingDomainResolving, findPrimaryDomainForDomainUsageType, findIncompleteSetupPrimaryDomainForDomainUsageType, isIncompleteSetupDomain, isHubSpotPageBuilderDomain, isHsSitesDomain, getIsPageBuilderDomainAvailable } from '../../utils/domains/Domains';
import { getIsDomainObjectHostingDomainUsageType, isDomainPrimaryOnlyForUnsupportedUsageTypes, areUsageTypesOnlyForUnsupportedDomainUsageTypes } from '../../utils/domains/UsageTypes';
import { getAllHostingDomainsAssociatedWithBrand, getConnectedReplacementDomain } from '../../utils/domains/Brands';
import getDomainIdPathParamFromProps from '../../utils/domains/getDomainIdPathParamFromProps';
import { getEmailDomainsListingState } from './EmailSendingDomains';
import { getTrackedDomainsListingState, getDomainObjects as getTrackedDomains } from './TrackedDomains';
import { getBrandToReplace, getReplacementBrand } from './DomainWizard';
import { getDedicatedIPListingState } from '../DedicatedIPv2';
import { getBrandDomainsListingState } from './Brands';
export const getDomainIds = state => state.domains.get('domainIds');
export const getDomainObjects = state => state.domains.get('domains');
const getDomainSetupTasks = state => state.domains.get('setupTasks');
export const getDomainRequestStatus = state => state.domains.get('domainRequestStatus');
export const getDomainsRequiringMigrationToDcvValue = state => state.domains.get('domainsRequiringMigrationToDcv').toArray();
export const getDomainsRequiringMigrationToDcv = createSelector([getDomainsRequiringMigrationToDcvValue], domainsRequiringMigrationToDcv => {
  return domainsRequiringMigrationToDcv;
});
export const getCreateSetupTaskRequestStatus = state => state.domains.get('createSetupTaskRequestStatus');
export const getDomainSetupTasksRequestStatus = state => state.domains.get('setupTasksRequestStatus');
export const getAreInternalDomainsShowing = state => state.domains.get('showingInternalDomains');
export const getPrimaryDomainUsageTypeAvailability = state => state.domains.get('primaryDomainUsageTypeAvailability');
export const getSupportedDomainUsageTypesForCustomDomain = state => state.domains.get('supportedDomainUsageTypesForCustomDomain').toArray();
export const getSupportedDomainUsageTypeForHsSites = state => state.domains.get('supportedDomainUsageTypeForHsSites').toArray();
export const getSupportedDomainUsageTypesForSecondary = state => state.domains.get('supportedSecondaryDomainUsageTypes').toArray();

// Returns true if all in scope content types:
// 1. Can support pagebuilder (www, blog, landing page only)
// 2. Do not have hs-sites-domain-write scopes (must use hs-sites instead)
export const getDoAllInScopeContentTypesSupportPageBuilder = createSelector([getSupportedDomainUsageTypesForCustomDomain, getSupportedDomainUsageTypeForHsSites], (supportedDomainUsageTypesForCustomDomain, supportedDomainUsageTypeForHsSites) => supportedDomainUsageTypesForCustomDomain.every(customDomainUsageType => getIsPageBuilderDomainAvailable(customDomainUsageType, supportedDomainUsageTypeForHsSites)));
export const getPrimarySetupTasks = createSelector([getDomainSetupTasks], setupTasks => {
  return setupTasks.filter(setupTask => setupTask.get('category') === DomainSetupTaskCategories.PRIMARY_CREATE);
});
export const getReplaceBrandSetupTasks = createSelector([getDomainSetupTasks], setupTasks => {
  return setupTasks.filter(setupTask => setupTask.get('category') === DomainSetupTaskCategories.BRAND_REPLACE);
});
export const getCosDomainsList = createSelector([getDomainObjects, getDomainIds], (domains, domainIds) => {
  return domainIds.map(id => domains.get(id));
});
export const getPrimaryDomainsList = createSelector([getCosDomainsList, getSupportedDomainUsageTypesForCustomDomain], (domains, supportedDomainUsageTypesForCustomDomain) => {
  return domains.filter(domain => domain.get('isAnyPrimary') && !domain.get('isLegacy') && !isDomainPrimaryOnlyForUnsupportedUsageTypes(domain, supportedDomainUsageTypesForCustomDomain));
});
export const getNonInternalPrimaryDomainsList = createSelector([getPrimaryDomainsList], primaryDomains => primaryDomains.filter(domain => !domain.get('isInternalDomain')));
export const getSecondaryDomainsWithIncompleteSetupPrimaryDomains = createSelector([getCosDomainsList], domains => {
  return domains.filter(domain => isSecondaryDomain(domain));
});
export const getSecondaryDomains = createSelector([getSecondaryDomainsWithIncompleteSetupPrimaryDomains, getPrimarySetupTasks], (secondaryDomainsWithIncompleteSetupPrimaryDomains, primarySetupTasksMap) => {
  return secondaryDomainsWithIncompleteSetupPrimaryDomains.filter(domainObj => {
    return !isIncompleteSetupDomain(domainObj, primarySetupTasksMap);
  });
});
export const getSecondaryDomainsSetupCompletedOrReadyForPublishing = createSelector([getSecondaryDomainsWithIncompleteSetupPrimaryDomains, getPrimarySetupTasks], (secondaryDomainsWithIncompleteSetupPrimaryDomains, primarySetupTasksMap) => secondaryDomainsWithIncompleteSetupPrimaryDomains.filter(domain => !isIncompleteSetupDomain(domain, primarySetupTasksMap) || domain.get('manuallyMarkedAsResolving')));
export const getConnectedPrimaryAndSecondaryDomains = createSelector([getPrimaryDomainsList, getSecondaryDomainsSetupCompletedOrReadyForPublishing], (primaryDomains, secondaryDomains) => {
  return Immutable.Set(primaryDomains.concat(secondaryDomains));
});
export const getDomainByDomainIdPathParam = createSelector([getCosDomainsList, (state, props) => parseInt(getDomainIdPathParamFromProps(props), 10)], (domains, domainId) => domains.find(domain => domain.get('id') === domainId));
export const getIncompleteSetupPrimaryDomains = createSelector([getSecondaryDomainsWithIncompleteSetupPrimaryDomains, getPrimarySetupTasks], (secondaryDomainsWithIncompleteSetupPrimaryDomains, primarySetupTasksMap) => {
  return secondaryDomainsWithIncompleteSetupPrimaryDomains.filter(domainObj => {
    return isIncompleteSetupDomain(domainObj, primarySetupTasksMap);
  });
});
export const getDomainUsageTypesWithPrimaryAssigned = createSelector([getSupportedDomainUsageTypesForCustomDomain, getPrimaryDomainUsageTypeAvailability, getNonInternalPrimaryDomainsList, getIncompleteSetupPrimaryDomains], (supportedDomainUsageTypesForCustomDomain, primaryDomainUsageTypeAvailability, nonInternalPrimaryDomains, incompleteSetupPrimaryDomains) => {
  return Object.keys(DomainUsageTypes).filter(domainUsageType => {
    const isDomainUsageTypeInScope = supportedDomainUsageTypesForCustomDomain.includes(domainUsageType);
    const isAvailabilityKeyAssigned = !primaryDomainUsageTypeAvailability.includes(domainUsageType);
    const foundPrimaryDomain = findPrimaryDomainForDomainUsageType(nonInternalPrimaryDomains, domainUsageType);
    const foundIncompleteSetupPrimaryDomain = findIncompleteSetupPrimaryDomainForDomainUsageType(incompleteSetupPrimaryDomains, domainUsageType);
    return isDomainUsageTypeInScope && (isAvailabilityKeyAssigned || !!(foundPrimaryDomain || foundIncompleteSetupPrimaryDomain));
  });
});
export const getAssignedDomainsCount = createSelector([getDomainUsageTypesWithPrimaryAssigned], alreadyAssignedDomainUsageTypes => {
  return alreadyAssignedDomainUsageTypes ? alreadyAssignedDomainUsageTypes.length : 0;
});
export const getIsAnyContentTypeUnassignedToPrimary = createSelector([getPrimaryDomainUsageTypeAvailability], primaryDomainUsageTypeAvailability => !!primaryDomainUsageTypeAvailability.length);
export const getRedirectDomains = createSelector([getCosDomainsList], domains => {
  return domains.filter(domain => getIsRedirectDomain(domain));
});
export const getDowngradedDomains = createSelector([getCosDomainsList, getSupportedDomainUsageTypesForCustomDomain], (domains, supportedDomainUsageTypesForCustomDomain) => {
  return domains.filter(domain => getIsDowngradedDomain(domain) || areUsageTypesOnlyForUnsupportedDomainUsageTypes(domain.get('usageTypes'), supportedDomainUsageTypesForCustomDomain));
});
export const getResolvingAndManuallyMarkedDomains = createSelector([getCosDomainsList], domains => {
  return domains.filter(domain => domain.get('isResolving') && !domain.get('isLegacy'));
});
export const getBrokenConnectionDomains = createSelector([getCosDomainsList], domains => {
  return domains.filter(domain => !isContentHostingDomainResolving(domain) && domain.get('isSetupComplete') && !domain.get('isUsedForReverseProxy') && !domain.get('isLegacy'));
});
export const getIncompleteSetupDomains = createSelector([getCosDomainsList], domains => {
  return domains.filter(domain => !domain.get('isSetupComplete') && !domain.get('isLegacy') && !domain.get('isUsedForReverseProxy'));
});

// Does not check for scopes, only if content type supports page builder at all
export const getPageBuilderDomainsWithInvalidContentTypes = createSelector([getCosDomainsList], domains => domains.filter(domain => isHubSpotPageBuilderDomain(domain) && Object.keys(DomainUsageTypes).some(domainUsageType => !PageBuilderDomainUsageTypes.includes(domainUsageType) && getIsDomainObjectHostingDomainUsageType(domain, domainUsageType))));
export const getPortalHasMultipleHsSitesDomains = createSelector([getCosDomainsList], domains => domains.filter(domain => isHsSitesDomain(domain)).length > 1);
export const getCosDomainsListingState = createSelector([getPrimaryDomainsList, getSecondaryDomainsWithIncompleteSetupPrimaryDomains, getRedirectDomains, getDowngradedDomains, getDomainRequestStatus], (primaryDomains, secondaryDomainsWithIncompleteSetupPrimaryDomains, redirectDomains, downgradedDomains, requestStatus) => {
  if (requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNINITIALIZED) {
    return ListingState.LOADING;
  } else if (requestStatus === RequestStatus.FAILED) {
    return ListingState.ERROR;
  }
  if (primaryDomains.size === 0 && secondaryDomainsWithIncompleteSetupPrimaryDomains.size === 0 && redirectDomains.size === 0 && downgradedDomains.size === 0) {
    return ListingState.EMPTY;
  }
  return ListingState.RETRIEVED;
});
export const getAllDomainsListingState = createSelector([getCosDomainsListingState, getEmailDomainsListingState, getTrackedDomainsListingState, getDedicatedIPListingState, getBrandDomainsListingState], (...listingStates) => {
  if (listingStates.some(listingState => listingState === ListingState.LOADING)) {
    return ListingState.LOADING;
  }
  if (listingStates.every(listingState => listingState === ListingState.EMPTY)) {
    return ListingState.EMPTY;
  }
  return ListingState.RETRIEVED;
});
export const getDerivedBrandNameToDomainsMap = createSelector([getDomainObjects], domains => {
  return domains.reduce((prev, domainObj) => {
    const listOfSameBrandDomains = prev.get(domainObj.get('derivedBrandName'));
    return prev.set(domainObj.get('derivedBrandName'), !listOfSameBrandDomains ? Immutable.List([domainObj]) : listOfSameBrandDomains.push(domainObj));
  }, Immutable.Map());
});
export const getBrandNameToLastUsedSuffixMap = createSelector([getDomainObjects], domains => domains.reduce((brandNameToSuffixesMap, domainObj) => brandNameToSuffixesMap.set(domainObj.get('derivedBrandName'), domainObj.get('publicSuffix')), Immutable.Map()));
export const getIsAnyReplacementDomainNotResolving = createSelector([getDerivedBrandNameToDomainsMap, getBrandToReplace, getReplacementBrand], (brandNameToDomainsMap, brandToReplace, replacementBrand) => {
  if (!brandToReplace || !replacementBrand) {
    return null;
  }
  return getAllHostingDomainsAssociatedWithBrand(brandNameToDomainsMap, brandToReplace).some(domainToReplace => {
    const replacementDomain = getConnectedReplacementDomain(brandNameToDomainsMap, replacementBrand, domainToReplace);
    return !replacementDomain || !isContentHostingDomainResolving(replacementDomain);
  });
});
export const getHostedTrackedDomainsAssociatedWithBrand = createSelector([getTrackedDomains, getBrandToReplace], (trackedDomains, brandToReplace) => {
  return trackedDomains.filter(trackedDomain => trackedDomain.get('domain').indexOf(`.${brandToReplace}.`) !== -1 && trackedDomain.get('hosted')).toList();
});