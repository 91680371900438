'use es6';

import * as ActionTypes from '../ActionTypes';
export function getDnsProviderSucceededAction(response, isEmailSendingDomain = false) {
  return {
    type: ActionTypes.CHECK_DNS_PROVIDER_SUCCEEDED,
    dnsProviderInfo: response,
    isEmailSendingDomain
  };
}
export function getDnsProviderAttemptedAction() {
  return {
    type: ActionTypes.CHECK_DNS_PROVIDER_ATTEMPTED
  };
}
export function getDnsProviderFailedAction() {
  return {
    type: ActionTypes.CHECK_DNS_PROVIDER_FAILED
  };
}