'use es6';

import { throttle } from 'underscore';
import http from 'hub-http/clients/apiClient';
import Immutable from 'immutable';
import * as VerifyDomainTypes from '../../enums/domains/VerifyDomainTypes';
import getEarlyRequesterResultPromise from '../../earlyRequester/getEarlyRequesterResultPromise';
export const DOMAINS_API_PATH = 'cos-domains/v1/domains';
const DASHBOARD_API_PATH = 'cos-domains/v1/domain-management/dashboard';
const BRANDS_API_PATH = 'cos-domains/v1/brands';
const SETUP_TASKS_API_PATH = 'cos-domains/v1/domain-setup-tasks';
const DOMAIN_TEAMS_API_PATH = 'cos-domains/v1/domain-teams';
const BRAND_BU_API_PATH = 'cos-domains/v1/brand-bu';
export function fetchDashboard(includeInternal) {
  const makeRequest = () => http.get(DASHBOARD_API_PATH, {
    query: {
      includeInternal
    },
    timeout: 40000
  });
  const quickFetchPromise = getEarlyRequesterResultPromise('dashboard');
  if (quickFetchPromise) {
    return quickFetchPromise.catch(makeRequest).then(Immutable.fromJS);
  }
  return makeRequest().then(Immutable.fromJS);
}
export function fetchBrands() {
  const makeRequest = () => http.get(`${BRANDS_API_PATH}/limits-info`);
  const quickFetchPromise = getEarlyRequesterResultPromise('brandDomains');
  if (quickFetchPromise) {
    return quickFetchPromise.catch(makeRequest).then(Immutable.fromJS);
  }
  return makeRequest().then(Immutable.fromJS);
}
export function fetchDashboardByBusinessUnit(businessUnitId, includeInternal) {
  const makeRequest = () => http.get(`${DASHBOARD_API_PATH}/bu/${businessUnitId}`, {
    query: {
      includeInternal
    },
    timeout: 40000
  });
  const quickFetchPromise = getEarlyRequesterResultPromise('domainsByBusinessUnit');
  if (quickFetchPromise) {
    return quickFetchPromise.catch(makeRequest).then(Immutable.fromJS);
  }
  return makeRequest().then(Immutable.fromJS);
}
export function fetchBrandsByBusinessUnit(businessUnitId) {
  const makeRequest = () => http.get(`cos-domains/v1/brands/bu/${businessUnitId}/limits-info`);
  const quickFetchPromise = getEarlyRequesterResultPromise('brandDomainsByBusinessUnit');
  if (quickFetchPromise) {
    return quickFetchPromise.catch(makeRequest).then(Immutable.fromJS);
  }
  return makeRequest().then(Immutable.fromJS);
}
export function addBrand(name) {
  return http.post(BRANDS_API_PATH, {
    data: {
      name
    }
  }).then(Immutable.fromJS);
}
export function deleteBrand(name) {
  return http.delete(`${BRANDS_API_PATH}/by-name/${name}`).then(Immutable.fromJS);
}
export function updateBrandBusinessUnit(buId, brandId) {
  return http.put(`${BRAND_BU_API_PATH}/bu/${buId}`, {
    data: {
      brandIds: [brandId]
    }
  }).then(Immutable.fromJS);
}
export function replaceBrand(brandToReplace, replacementBrand, domainReplacements) {
  // Payload includes domain replacements if connecting new brand
  const payload = domainReplacements && !domainReplacements.isEmpty() ? {
    brandToReplace,
    replacementBrand,
    domainReplacements
  } : {
    brandToReplace,
    replacementBrand
  };
  return http.post(`${BRANDS_API_PATH}/replace`, {
    data: payload
  }).then(Immutable.fromJS);
}
export function fetchDnsProvider(apexDomain) {
  const uri = `${DOMAINS_API_PATH}/${apexDomain}/dns-provider`;
  return http.get(uri).then(Immutable.fromJS);
}
export function removeDomainNameWithRedirects(domainId) {
  const uri = `${DOMAINS_API_PATH}/${domainId}/delete-hard-with-redirects`;
  return http.delete(uri);
}
export function removeDomainName(domainId) {
  const uri = `${DOMAINS_API_PATH}/${domainId}/delete-hard`;
  return http.delete(uri);
}
export function validateDomain(domainName, domainType = VerifyDomainTypes.CONTENT_HOSTING) {
  const uri = `${DOMAINS_API_PATH}/validity`;
  return http.get(uri, {
    query: {
      domainType,
      domainName
    }
  }).then(Immutable.fromJS);
}
export function addSystemRedirect(systemDomain, redirectToDomain) {
  const uri = DOMAINS_API_PATH;
  return http.post(uri, {
    data: {
      domain: systemDomain,
      isPrimary: 'false',
      secondaryToDomain: redirectToDomain,
      isInternal_domain: true,
      isResolving: true,
      isDnsCorrect: true,
      isSslEnabled: true,
      isSetupComplete: true
    },
    timeout: 40000
  }).then(Immutable.fromJS);
}
export function addDomains(domainObj, options) {
  const uri = `${SETUP_TASKS_API_PATH}/for-domains`;
  return http.post(uri, {
    data: Object.assign({}, domainObj, {
      isReverseProxySetup: options.isReverseProxySetup
    }),
    timeout: 40000
  }).then(Immutable.fromJS);
}
export function fetchPrimarySetupTasks() {
  return http.get(`${SETUP_TASKS_API_PATH}?category=1&limit=200`).then(Immutable.fromJS);
}
export const fetchTaskDomainsVerificationInfo = throttle((taskId, triggerNextCheck) => {
  const uri = `${SETUP_TASKS_API_PATH}/${taskId}/verify`;
  return http.put(uri, {
    query: {
      triggerNextCheck
    },
    timeout: 40000
  }).then(Immutable.fromJS);
}, 5000, {
  trailing: false
});
export function updateDomain(domainObj) {
  const uri = `${DOMAINS_API_PATH}/${domainObj.get('id')}`;
  return http.put(uri, {
    data: domainObj
  }).then(Immutable.fromJS);
}
export function replaceDomain(domainObj) {
  const uri = `${DOMAINS_API_PATH}/${domainObj.id}/replace-primary`;
  return http.put(uri, {
    data: domainObj
  }).then(Immutable.fromJS);
}
export function createSetupTask(domainId) {
  const uri = `${SETUP_TASKS_API_PATH}/for-reconnection`;
  return http.post(uri, {
    data: {
      domainId
    }
  }).then(Immutable.fromJS);
}
export function applyDomainConnectTemplate(domainId, template) {
  const uri = `${DOMAINS_API_PATH}/${domainId}/domain-connect/apply-change/${template}`;
  return http.put(uri, {
    timeout: 20000
  });
}
export function updateDomainTeams(domainId, teamIds) {
  const uri = `${DOMAIN_TEAMS_API_PATH}/domains/${domainId}`;
  return http.put(uri, {
    data: {
      teamIds
    }
  }).then(Immutable.fromJS);
}
export function fetchDomainTeams(domainId) {
  const uri = `${DOMAIN_TEAMS_API_PATH}/domains/${domainId}`;
  return http.get(uri).then(Immutable.fromJS);
}
const READY_STATE_DONE = 4;
let fetchDomainLanguageRequest;
export function fetchDomainLanguage(suffix) {
  // Allow only one request at a time to prevent out of order responses overriding state
  if (fetchDomainLanguageRequest && fetchDomainLanguageRequest.readyState !== READY_STATE_DONE) {
    fetchDomainLanguageRequest.abort();
  }
  return http.get(`${SETUP_TASKS_API_PATH}/languages?tld=${suffix}`, {
    withXhr: xhr => {
      fetchDomainLanguageRequest = xhr;
    }
  }).then(Immutable.fromJS);
}
export function enablePreProvisionSsl(taskId) {
  return http.put(`${SETUP_TASKS_API_PATH}/${taskId}/preprovision`).then(Immutable.fromJS);
}
export function verifyPreProvisionSsl(taskId) {
  return http.get(`${SETUP_TASKS_API_PATH}/${taskId}/verify-preprovision-records`).then(Immutable.fromJS);
}