import * as BrandNameErrorType from '../../../enums/domains/BrandNameErrorType';
import getIsBrandNameValid, { getIsBrandNameValidInternational } from './getIsBrandNameValid';
export default function getBrandNameErrorType(brandName, brandNames) {
  if (brandName) {
    if (!getIsBrandNameValidInternational(brandName)) {
      return BrandNameErrorType.INVALID_CHARACTER_INTERNATIONAL;
    } else if (!getIsBrandNameValid(brandName)) {
      return BrandNameErrorType.INVALID_CHARACTER;
    }
    if (brandNames.includes(brandName)) {
      return BrandNameErrorType.DUPLICATE;
    }
  }
  return undefined;
}