import Raven from 'raven-js';
import { calculateInitializeAutomaticRedirectPayload, trackUpdateAutomaticRedirect } from '../../utils/domains/automaticRedirect';
import { getAutomaticRedirect } from '../../selectors/domains/AutomaticRedirect';
import { AutomaticRedirectActions } from '../ActionTypes';
export const initializeAutomaticRedirect = (...params) => {
  const payloadPromise = calculateInitializeAutomaticRedirectPayload(...params);
  return dispatch => {
    payloadPromise.then(payload => {
      dispatch(Object.assign({
        type: AutomaticRedirectActions.initialize
      }, payload));
    }).catch(() => {
      Raven.captureMessage('Unexpected error when trying to initialize integrated redirect', {
        extra: Object.assign({}, params)
      });
    });
  };
};
export const updateAutomaticRedirect = params => {
  return (dispatch, getState) => {
    const {
      enabled: existingEnabled
    } = getAutomaticRedirect(getState());
    const {
      enabled,
      subdomain
    } = params;
    dispatch(Object.assign({
      type: AutomaticRedirectActions.update
    }, params));
    trackUpdateAutomaticRedirect({
      existingEnabled,
      enabled,
      subdomain
    });
  };
};
export function clearAutomaticRedirect() {
  return {
    type: AutomaticRedirectActions.reset
  };
}