import { createSelector } from 'reselect';
import getShouldShowDowngrade from '../../utils/domains/getShouldShowDowngrade';
import * as DowngradeCategories from '../../enums/domains/DowngradeCategories';
export const getDowngradeNotifications = state => state.downgradeNotifications.get('downgrades');
export const getDisplayableDowngradeNotifications = createSelector([getDowngradeNotifications], downgrades => {
  return downgrades.filter(downgrade => getShouldShowDowngrade(downgrade));
});
export const getPortalHasDowngrades = createSelector([getDowngradeNotifications], downgrades => downgrades && !downgrades.isEmpty());
export const getMultiCustomDomainRemovalCount = createSelector([getDowngradeNotifications], downgrades => {
  const multiCustomDomainDowngrade = downgrades.find(downgrade => downgrade.get('downgradeCategory') === DowngradeCategories.MULTI_CUSTOM_DOMAINS);
  if (!multiCustomDomainDowngrade) {
    return 0;
  }
  return parseInt(multiCustomDomainDowngrade.get('additionalInfo').get('NUM_TO_REMOVE'), 10);
});