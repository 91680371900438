'use es6';

import Raven from 'raven-js';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import * as ActionTypes from '../ActionTypes';
import * as Domains from '../../api/domains/Domains';
import { getDnsProviderAttemptedAction, getDnsProviderSucceededAction, getDnsProviderFailedAction } from './shared';
import { trackRemoveDomainUsageType } from '../../utils/trackActions';
import { getAddBrandFailureNotification, getAddBrandSuccessNotification, getDeleteBrandFailureNotification, getDeleteBrandSuccessNotification, getFetchBrandFailedNotification, getRemoveDomainSuccessNotification, getRemoveDomainFailedNotification, getI18nApiWarning, getUpdateBrandBusinessUnitSuccessNotification, getUpdateBrandBusinessUnitFailureNotification } from '../../utils/domains/langUtils';
import { getContinueDomainSetupUrlPath } from '../../utils/domains/urlUtils';
import { history } from '../../utils/createHistory';
import { fetchDowngradeNotifications } from './DowngradeNotifications';
import { getPortalHasDowngrades } from '../../selectors/domains/DowngradeNotifications';
import { getIsUngatedForAutomaticRedirect, getIsUngatedForSslProvisioningTool } from '../../selectors/Auth';
export function getAddBrandRequestPendingAction() {
  return {
    type: ActionTypes.ADD_BRAND_ATTEMPTED
  };
}
export function getAddBrandRequestSucceededAction(addedBrand) {
  return {
    type: ActionTypes.ADD_BRAND_SUCCEEDED,
    addedBrand
  };
}
export function getAddBrandRequestFailedAction() {
  return {
    type: ActionTypes.ADD_BRAND_FAILED
  };
}
export function addBrand(brandName) {
  return dispatch => {
    dispatch(getAddBrandRequestPendingAction());
    return Domains.addBrand(brandName).then(addedBrand => {
      FloatingAlertStore.addAlert(getAddBrandSuccessNotification(brandName));
      dispatch(getAddBrandRequestSucceededAction(addedBrand));
    }).catch(() => {
      FloatingAlertStore.addAlert(getAddBrandFailureNotification(brandName));
      dispatch(getAddBrandRequestFailedAction());
    });
  };
}
export function getDeleteBrandAttemptedAction() {
  return {
    type: ActionTypes.DELETE_BRAND_ATTEMPTED
  };
}
export function getDeleteBrandFailedAction() {
  return {
    type: ActionTypes.DELETE_BRAND_FAILED
  };
}
export function getDeleteBrandSucceededAction(brandName) {
  return {
    type: ActionTypes.DELETE_BRAND_SUCCEEDED,
    deletedBrandName: brandName
  };
}
export function deleteBrand(brandName) {
  return (dispatch, getState) => {
    dispatch(getDeleteBrandAttemptedAction());
    return Domains.deleteBrand(brandName).then(() => {
      FloatingAlertStore.addAlert(getDeleteBrandSuccessNotification(brandName));
      dispatch(getDeleteBrandSucceededAction(brandName));
      if (getPortalHasDowngrades(getState())) {
        dispatch(fetchDowngradeNotifications());
      }
    }).catch(() => {
      FloatingAlertStore.addAlert(getDeleteBrandFailureNotification(brandName));
      dispatch(getDeleteBrandFailedAction());
    });
  };
}
export function updateBrandBusinessUnit(businessUnitId, brandId, currentViewBusinessUnitId) {
  return dispatch => {
    dispatch({
      type: ActionTypes.UPDATE_BRAND_BUSINESS_UNIT_ATTEMPTED
    });
    Domains.updateBrandBusinessUnit(businessUnitId, brandId).then(() => {
      FloatingAlertStore.addAlert(getUpdateBrandBusinessUnitSuccessNotification());
      dispatch({
        type: ActionTypes.UPDATE_BRAND_BUSINESS_UNIT_SUCCEEDED
      });
      if (currentViewBusinessUnitId === 0 || currentViewBusinessUnitId) {
        dispatch(fetchBrandsByBusinessUnit(currentViewBusinessUnitId));
      }
    }, () => {
      FloatingAlertStore.addAlert(getUpdateBrandBusinessUnitFailureNotification());
      dispatch({
        type: ActionTypes.UPDATE_BRAND_BUSINESS_UNIT_FAILED
      });
    }).catch(err => {
      throw err;
    });
  };
}
export function getFetchBrandsPendingAction() {
  return {
    type: ActionTypes.FETCH_BRANDS_ATTEMPTED
  };
}
export function getFetchBrandsSuccessAction(brands, portalBrandCount, allowedBrandCount) {
  return {
    type: ActionTypes.FETCH_BRANDS_SUCCEEDED,
    brands,
    portalBrandCount,
    allowedBrandCount
  };
}
export function getFetchBrandsFailedAction() {
  FloatingAlertStore.addAlert(getFetchBrandFailedNotification());
  return {
    type: ActionTypes.FETCH_BRANDS_FAILED
  };
}
export function fetchBrands() {
  return dispatch => {
    dispatch(getFetchBrandsPendingAction());
    Domains.fetchBrands().then(resp => {
      dispatch(getFetchBrandsSuccessAction(resp.get('brands'), resp.get('quantityUsed'), resp.get('quantityAllowed')));
    }, err => {
      Raven.captureException(err, {
        tags: {
          action: ActionTypes.FETCH_BRANDS_FAILED
        }
      });
      dispatch(getFetchBrandsFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function fetchBrandsByBusinessUnit(businessUnitId) {
  return dispatch => {
    dispatch(getFetchBrandsPendingAction());
    Domains.fetchBrandsByBusinessUnit(businessUnitId).then(resp => {
      dispatch(getFetchBrandsSuccessAction(resp.get('brands'), resp.get('quantityUsed'), resp.get('quantityAllowed')));
    }, err => {
      Raven.captureException(err, {
        tags: {
          action: ActionTypes.FETCH_BRANDS_FAILED
        }
      });
      dispatch(getFetchBrandsFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getFetchDashboardPendingAction({
  includeInternal
}) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_ATTEMPTED,
    includeInternal
  };
}
export function getFetchDashboardSuccessAction(dashboardResponse, isUngatedForSslProvisioningTool) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_SUCCEEDED,
    dashboardResponse,
    isUngatedForSslProvisioningTool
  };
}
export function getFetchDashboardFailedAction() {
  return {
    type: ActionTypes.FETCH_DASHBOARD_FAILED
  };
}
export function fetchDashboard({
  includeInternal
}) {
  return (dispatch, getState) => {
    dispatch(getFetchDashboardPendingAction({
      includeInternal
    }));
    const isUngatedForSslProvisioningTool = getIsUngatedForSslProvisioningTool(getState());
    Domains.fetchDashboard(includeInternal).then(dashboardResponse => {
      dispatch(getFetchDashboardSuccessAction(dashboardResponse, isUngatedForSslProvisioningTool));
    }, err => {
      Raven.captureException(err, {
        tags: {
          action: ActionTypes.FETCH_DASHBOARD_FAILED
        }
      });
      dispatch(getFetchDashboardFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function fetchDashboardByBusinessUnit(businessUnitId, {
  includeInternal
}) {
  return (dispatch, getState) => {
    dispatch(getFetchDashboardPendingAction({
      includeInternal
    }));
    const isUngatedForSslProvisioningTool = getIsUngatedForSslProvisioningTool(getState());
    Domains.fetchDashboardByBusinessUnit(businessUnitId, includeInternal).then(dashboard => {
      dispatch(getFetchDashboardSuccessAction(dashboard, isUngatedForSslProvisioningTool));
    }, err => {
      Raven.captureException(err, {
        tags: {
          action: ActionTypes.FETCH_DASHBOARD_FAILED
        }
      });
      dispatch(getFetchDashboardFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getRemoveDomainAttemptedAction() {
  return {
    type: ActionTypes.REMOVE_DOMAIN_ATTEMPTED
  };
}
export function getRemoveDomainSuccessAction(domainId, domainName, domainType, domainUsageType, isUngatedForAutomaticRedirect) {
  return {
    type: ActionTypes.REMOVE_DOMAIN_SUCCEEDED,
    domainId,
    deletedDomainName: domainName,
    domainType,
    domainUsageType,
    isUngatedForAutomaticRedirect
  };
}
export function getRemoveDomainFailedAction() {
  return {
    type: ActionTypes.REMOVE_DOMAIN_FAILED
  };
}
export function removeDomainName(domainId, domainName, isRedirectDomain, domainType, domainUsageType) {
  return (dispatch, getState) => {
    dispatch(getRemoveDomainAttemptedAction());
    const isUngatedForAutomaticRedirect = getIsUngatedForAutomaticRedirect(getState());
    let removeDomainNamePromise;
    if (isRedirectDomain || !isUngatedForAutomaticRedirect) {
      removeDomainNamePromise = Domains.removeDomainName(domainId);
    } else {
      removeDomainNamePromise = Domains.removeDomainNameWithRedirects(domainId);
    }
    return removeDomainNamePromise.then(() => {
      dispatch(getRemoveDomainSuccessAction(domainId, domainName, domainType, domainUsageType, isUngatedForAutomaticRedirect));
      FloatingAlertStore.addAlert(getRemoveDomainSuccessNotification(domainName));
      if (domainUsageType) {
        trackRemoveDomainUsageType({
          domainType,
          domainUsageType,
          disconnected: true
        });
      }
      if (getPortalHasDowngrades(getState())) {
        dispatch(fetchDowngradeNotifications());
      }
    }).catch(() => {
      dispatch(getRemoveDomainFailedAction());
      FloatingAlertStore.addAlert(getRemoveDomainFailedNotification());
    });
  };
}
export function checkDnsProvider(apexDomain, isEmailSendingDomain = false) {
  return dispatch => {
    if (apexDomain) {
      dispatch(getDnsProviderAttemptedAction());
      Domains.fetchDnsProvider(apexDomain).then(response => {
        dispatch(getDnsProviderSucceededAction(response, isEmailSendingDomain));
      }, () => dispatch(getDnsProviderFailedAction())).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
    } else {
      dispatch(getDnsProviderFailedAction());
    }
  };
}
export function getCreateSetupTaskAttemptedAction() {
  return {
    type: ActionTypes.CREATE_SETUP_TASK_ATTEMPTED
  };
}
export function getCreateSetupTaskSucceededAction() {
  return {
    type: ActionTypes.CREATE_SETUP_TASK_SUCCEEDED
  };
}
export function getCreateSetupTaskFailedAction() {
  return {
    type: ActionTypes.CREATE_SETUP_TASK_FAILED
  };
}
export function createSetupTaskAndRedirect(match, domainId, domainType) {
  return dispatch => {
    dispatch(getCreateSetupTaskAttemptedAction());
    Domains.createSetupTask(domainId).then(resp => {
      history.push(`${getContinueDomainSetupUrlPath(match, resp.getIn(['domains', 0]), domainType)}`);
      dispatch(getCreateSetupTaskSucceededAction());
    }, () => {
      dispatch(getCreateSetupTaskFailedAction());
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('createSetupTaskForReconnection.failed.message'),
        titleText: getI18nApiWarning('createSetupTaskForReconnection.failed.title'),
        type: 'danger'
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getFetchPrimarySetupTasksAttemptedAction() {
  return {
    type: ActionTypes.FETCH_PRIMARY_SETUP_TASKS_ATTEMPTED
  };
}
export function getFetchPrimarySetupTasksSucceededAction(setupTasks) {
  return {
    type: ActionTypes.FETCH_PRIMARY_SETUP_TASKS_SUCCEEDED,
    setupTasks
  };
}
export function getFetchPrimarySetupTasksFailedAction() {
  return {
    type: ActionTypes.FETCH_PRIMARY_SETUP_TASKS_FAILED
  };
}
export function fetchPrimarySetupTasks() {
  return dispatch => {
    dispatch(getFetchPrimarySetupTasksAttemptedAction());
    Domains.fetchPrimarySetupTasks().then(resp => {
      dispatch(getFetchPrimarySetupTasksSucceededAction(resp.get('objects')));
    }, () => {
      dispatch(getFetchPrimarySetupTasksFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getFetchDomainTeamsPendingAction() {
  return {
    type: ActionTypes.FETCH_DOMAIN_TEAMS_ATTEMPTED
  };
}
export function getFetchDomainTeamsSuccessAction(teamIds) {
  return {
    type: ActionTypes.FETCH_DOMAIN_TEAMS_SUCCEEDED,
    teamIds
  };
}
export function getFetchDomainTeamsFailedAction() {
  return {
    type: ActionTypes.FETCH_DOMAIN_TEAMS_FAILED
  };
}
export function fetchDomainTeams(domainId) {
  return dispatch => {
    dispatch(getFetchDomainTeamsPendingAction());
    Domains.fetchDomainTeams(domainId).then(teamIds => {
      dispatch(getFetchDomainTeamsSuccessAction(teamIds));
    }, () => {
      dispatch(getFetchDomainTeamsFailedAction());
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('fetchDomainTeams.failed.message'),
        titleText: getI18nApiWarning('fetchDomainTeams.failed.title'),
        type: 'danger'
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export const toggleBuiltInCdnUsage = useBuiltInCdn => {
  return dispatch => {
    dispatch({
      type: ActionTypes.CreateReverseProxyActions.builtInCdnToggled,
      useBuiltInCdn
    });
  };
};