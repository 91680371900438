import { createSelector } from 'reselect';
import { RequestStatus, ListingState } from '../../Constants';
import { getUngatedForDisplayingTrackedDomains } from '../Auth';
export const getDomainIds = state => state.trackedDomains.get('domainIds');
export const getDomainObjects = state => state.trackedDomains.get('domains');
export const getRequestStatus = state => state.trackedDomains.get('trackedDomainsRequestStatus');
export const getTrackedDomainsListingState = createSelector([getDomainObjects, getRequestStatus, getUngatedForDisplayingTrackedDomains], (domains, requestStatus, isUngatedForDisplayingTrackedDomains) => {
  if (requestStatus === RequestStatus.PENDING || isUngatedForDisplayingTrackedDomains && requestStatus === RequestStatus.UNINITIALIZED) {
    return ListingState.LOADING;
  } else if (requestStatus === RequestStatus.FAILED) {
    return ListingState.ERROR;
  }
  if (domains.size === 0) {
    return ListingState.EMPTY;
  }
  return ListingState.RETRIEVED;
});
export const getTrackedDomainsList = createSelector([getDomainObjects, getDomainIds], (domains, domainIds) => domainIds.map(id => domains.get(id)));