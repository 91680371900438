'use es6';

import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';
export function getFetchSecuritySettingsRequestStatus(state) {
  return state.domainSecuritySettings.get('fetchSecuritySettingsRequestStatus');
}
export function getUpdateSecuritySettingsRequestStatus(state) {
  return state.domainSecuritySettings.get('updateSecuritySettingsRequestStatus');
}
export function getIsSecuritySettingSelected(state, securityHeader) {
  const securityHeaderStagedValue = state.domainSecuritySettings.getIn(['stagedChanges', securityHeader]);
  if (typeof securityHeaderStagedValue === 'boolean') {
    return securityHeaderStagedValue;
  }
  return securityHeaderStagedValue !== undefined;
}
export function getStagedChanges(state) {
  return state.domainSecuritySettings.get('stagedChanges');
}
export function getOriginalSettings(state) {
  return state.domainSecuritySettings.get('securitySettings');
}
export function getDefaultValue(state, securityHeader) {
  return state.domainSecuritySettings.getIn(['stagedChanges', securityHeader, 'value']);
}
export function getInitialTlsValue(state) {
  return state.domainSecuritySettings.get('initialTlsVersion');
}
export const getDefaultContentSecurityPolicyUserInputValue = createSelector(getDefaultValue, (value = ImmutableMap()) => ImmutableMap.isMap(value) ? value.get('userInputValue') : undefined);
export const getDefaultContentSecurityPolicyEnableNonceValue = createSelector(getDefaultValue, (value = ImmutableMap()) => ImmutableMap.isMap(value) ? value.get('enableNonce') : false);