import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
/**
 * GET users/v2/app/hub-users
 */
export const getUsers = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('users/v2/app/hub-users');
};
export const getSpecificUsers = (ids, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('users/v2/app/hub-users', {
    query: {
      ids
    }
  });
};

/**
 * GET notification-station/general/v1/notification-recipients
 */
export const getUsersCanNotify = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('notification-station/general/v1/notification-recipients');
};