import { UsageTrackingDomainUsageTypes, UsageTrackingDomainTypes } from '../Constants';
import UsageTracker from './usageTracker';
export const trackRemoveDomainUsageType = ({
  domainType,
  domainUsageType,
  disconnected = false
}) => {
  UsageTracker.track('removeDomainUsageType', {
    action: 'remove-domain-usage-type',
    'domain-type': UsageTrackingDomainTypes[domainType],
    'content-type': UsageTrackingDomainUsageTypes[domainUsageType],
    disconnected
  });
};