'use es6';

import http from 'hub-http/clients/apiClient';
import Immutable from 'immutable';
const API_PATH = 'cos-domains/v1/domain-management';
export function fetchDomainSecuritySettings(domainId) {
  return http.get(`${API_PATH}/${domainId}/security-settings`).then(Immutable.fromJS);
}
export function updateSettings(domainId, settings) {
  return http.post(`${API_PATH}/${domainId}/security-settings/sync`, {
    data: settings
  }).then(Immutable.fromJS);
}