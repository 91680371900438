/* hs-eslint ignored failing-rules */

'use es6';

import * as ActionTypes from '../ActionTypes';
import * as TrackedDomains from '../../api/domains/TrackedDomains';
export function getFetchTrackedDomainsPendingAction() {
  return {
    type: ActionTypes.FETCH_TRACKED_DOMAINS_ATTEMPTED
  };
}
export function getFetchTrackedDomainsSuccessAction(domains) {
  return {
    type: ActionTypes.FETCH_TRACKED_DOMAINS_SUCCEEDED,
    domains
  };
}
export function getFetchTrackedDomainsFailedAction() {
  return {
    type: ActionTypes.FETCH_TRACKED_DOMAINS_FAILED
  };
}
export function fetchTrackedDomains() {
  return dispatch => {
    dispatch(getFetchTrackedDomainsPendingAction());
    TrackedDomains.getTrackedDomains().then(domains => {
      dispatch(getFetchTrackedDomainsSuccessAction(domains));
    }, () => {
      dispatch(getFetchTrackedDomainsFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}