import { useEffect, useState } from 'react';
import Raven from 'raven-js';

// @ts-expect-error untyped module
import { getInitialOptinView } from 'ui-addon-opt/state/UIOptAvailability';
import { OPTIN_VIEW_NEW } from 'ui-addon-opt/constants/UIOptConstants';
export default (optinName => {
  const [isOptedIntoBeta, setIsOptedIntoBeta] = useState(false);
  useEffect(() => {
    getInitialOptinView(optinName).then(optInView => {
      setIsOptedIntoBeta(optInView === OPTIN_VIEW_NEW);
    }).catch(error => {
      const extra = Object.assign({}, error, {
        optinName
      });
      Raven.captureMessage(`Error fetching optin availability for ${optinName}`, {
        extra
      });
    });
  }, [optinName]);
  return {
    isOptedIntoBeta
  };
});