import Immutable from 'immutable';
export function getBrandNameFromAddDomainResponse(currentBrands, resp) {
  // Adding domains only allows the introduction of one new brand name at a time,
  // so we might as well pick the derived brand name of the first one
  // since each domain in the response will have an equivalent derived brand name
  const firstDomain = resp.get('domains').first();
  // If for some reason the response did not return any domains, there's nothing to do here
  if (!firstDomain) {
    return undefined;
  }
  const derivedBrandName = firstDomain.get('derivedBrandName');
  if (currentBrands.includes(derivedBrandName)) {
    return undefined;
  }
  return Immutable.fromJS({
    name: derivedBrandName
  });
}