'use es6';

import { DomainType } from '../../Constants';
import { getCosDomainTypePathParamForContinueSetup } from '../../utils/domains/Domains';
import { getBrokenConnectionDomains, getIncompleteSetupDomains, getPrimarySetupTasks, getReplaceBrandSetupTasks } from './Domains';
import { getInvalidEmailSendingDomains } from './EmailSendingDomains';
import { createSelector } from 'reselect';
export const getDomainAlerts = state => {
  return state.domainAlerts.get('domainAlerts');
};
export const getDomainTypePathParam = createSelector([getBrokenConnectionDomains, getIncompleteSetupDomains, getInvalidEmailSendingDomains, getPrimarySetupTasks, getReplaceBrandSetupTasks, (_, domain) => domain], (brokenConnectionDomains, incompleteSetupDomains, invalidEmailSendingDomains, replaceBrandSetupTasks, primarySetupTasks, domain) => {
  if (brokenConnectionDomains.includes(domain)) {
    return getCosDomainTypePathParamForContinueSetup(domain, primarySetupTasks);
  } else if (incompleteSetupDomains.includes(domain)) {
    return getCosDomainTypePathParamForContinueSetup(domain, primarySetupTasks, replaceBrandSetupTasks);
  } else if (invalidEmailSendingDomains.includes(domain)) {
    return DomainType.EMAIL;
  }
  return null;
});