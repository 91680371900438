import RequestStatusTypes from '../constants/RequestStatusTypes';
export const allFinishedWithSuccess = (...requestStatuses) => {
  return requestStatuses.every(requestStatus => requestStatus === RequestStatusTypes.SUCCEEDED);
};
export const anyRequestsFailed = (...requestStatuses) => {
  return requestStatuses.includes(RequestStatusTypes.FAILED);
};
export const allRequestsInitiated = (...requestStatuses) => {
  return requestStatuses.every(requestStatus => typeof requestStatus !== 'undefined' && requestStatus !== RequestStatusTypes.UNINITIALIZED);
};
export const anyRequestsPending = (...requestStatuses) => {
  return requestStatuses.includes(RequestStatusTypes.PENDING);
};
export const allRequestsFinished = (...requestStatuses) => {
  return requestStatuses.every(requestStatus => requestStatus === RequestStatusTypes.SUCCEEDED || requestStatus === RequestStatusTypes.FAILED);
};