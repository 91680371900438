import { registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
// @ts-expect-error not migrated yet
import { DOMAINS_API_PATH } from '../api/domains/Domains';
export default registerQuery({
  fieldName: 'connectingBrandAvailability',
  args: ['domainName', 'domainType'],
  fetcher({
    domainName,
    domainType
  }) {
    return http.get(`${DOMAINS_API_PATH}/connecting-brand-availability`, {
      query: {
        domainName,
        domainType
      }
    });
  }
});