'use es6';

import Immutable from 'immutable';
import * as ActionTypes from '../ActionTypes';
import * as Domains from '../../api/domains/Domains';
import * as DomainDnsRecordsApi from '../../api/domains/DomainDnsRecords';
import * as ReverseProxyApi from '../../api/domains/ReverseProxy';
import { getI18nApiWarning, getFormattedI18nCustomErrorMessage, getRemoveUsageTypeSuccessNotification, getRemoveUsageTypeFailureNotification } from '../../utils/domains/langUtils';
import { getFieldsForReplaceRequest, getRemoveContentTypeRequestPayload } from '../../utils/domains/Domains';
import { trackRemoveDomainUsageType } from '../../utils/trackActions';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
export function requireHttps(checked) {
  return {
    type: ActionTypes.REQUIRE_HTTPS,
    checked
  };
}
export function setResolvingStatusManually(checked) {
  return {
    type: ActionTypes.MANUALLY_MARK_AS_RESOLVING,
    checked
  };
}

/**
 * Attempts to add a new staged domainUsageType
 * when a user toggles a ContentTypePicker button "on"
 *
 * Reducer is responsible for preventing duplicates
 */

export function addStagedDomainUsageType(newStagedDomainUsageType) {
  return {
    type: ActionTypes.ADD_STAGED_DOMAIN_USAGE_TYPE,
    newStagedDomainUsageType
  };
}

/**
 * Attempts to remove a staged domainUsageType
 * when a user toggles a ContentTypePicker "off"
 *
 * Reducer is responsible for safe removal
 */
export function removeStagedDomainUsageType(removedStagedDomainUsageType) {
  return {
    type: ActionTypes.REMOVE_STAGED_DOMAIN_USAGE_TYPE,
    removedStagedDomainUsageType
  };
}

/**
 * Completely overwrites stagedDomainUsageTypes with the provided domainUsageTypes
 *
 * Note: this only affects the staged usage types - request payload util will
 *       add back all existing usage types before updateDomain API call
 */
export function updateStagedDomainUsageTypes(stagedDomainUsageTypes) {
  return {
    type: ActionTypes.UPDATE_STAGED_DOMAIN_USAGE_TYPES,
    stagedDomainUsageTypes
  };
}
export function getUpdateDomainPendingAction() {
  return {
    type: ActionTypes.UPDATE_DOMAIN_ATTEMPTED
  };
}
export function getUpdateDomainSuccessAction(domain) {
  return {
    type: ActionTypes.UPDATE_DOMAIN_SUCCEEDED,
    domain
  };
}
export function getUpdateDomainFailedAction() {
  return {
    type: ActionTypes.UPDATE_DOMAIN_FAILED
  };
}
export function getRemoveContentTypePendingAction() {
  return {
    type: ActionTypes.REMOVE_CONTENT_TYPE_ATTEMPTED
  };
}
export function getRemoveContentTypeSuccessAction(domain, domainType, domainUsageType) {
  return {
    type: ActionTypes.REMOVE_CONTENT_TYPE_SUCCEEDED,
    domain,
    domainType,
    domainUsageType
  };
}
export function getRemoveContentTypeFailedAction() {
  return {
    type: ActionTypes.REMOVE_CONTENT_TYPE_FAILED
  };
}
function getErrorType(error) {
  if (error && error.responseJSON) {
    return error.responseJSON.errorType;
  }
  return undefined;
}
function getErrorTokens(error) {
  if (error && error.responseJSON) {
    return error.responseJSON.errorTokens;
  }
  return {};
}
function getEditDomainFailedMessage(errorType, tokens) {
  if (errorType === 'DOMAIN_SLUG_CONFLICT') {
    return getFormattedI18nCustomErrorMessage(Immutable.fromJS({
      errorType,
      tokens
    }));
  }
  return getI18nApiWarning('updateDomain.failed.message');
}
export function updateDomain(domainObj) {
  return dispatch => {
    dispatch(getUpdateDomainPendingAction());
    Domains.updateDomain(domainObj).then(domain => {
      dispatch(getUpdateDomainSuccessAction(domain));
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('updateDomain.success.message'),
        type: 'success'
      });
    }, error => {
      dispatch(getUpdateDomainFailedAction());
      FloatingAlertStore.addAlert({
        message: getEditDomainFailedMessage(getErrorType(error), getErrorTokens(error)),
        titleText: getI18nApiWarning('updateDomain.failed.title'),
        type: 'danger'
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function removeContentType(domainId, domainType, domainUsageType, currentUsageTypes) {
  return dispatch => {
    dispatch(getRemoveContentTypePendingAction());
    Domains.updateDomain(getRemoveContentTypeRequestPayload(domainId, domainUsageType, currentUsageTypes)).then(domain => {
      dispatch(getRemoveContentTypeSuccessAction(domain, domainType, domainUsageType));
      FloatingAlertStore.addAlert(getRemoveUsageTypeSuccessNotification(domainUsageType));
      if (domainUsageType) {
        trackRemoveDomainUsageType({
          domainType,
          domainUsageType
        });
      }
    }, () => {
      dispatch(getRemoveContentTypeFailedAction());
      FloatingAlertStore.addAlert(getRemoveUsageTypeFailureNotification(domainUsageType));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function replaceDomain(domainMap) {
  return dispatch => {
    dispatch(getUpdateDomainPendingAction());
    Domains.replaceDomain(getFieldsForReplaceRequest(domainMap.toJS())).then(resp => {
      resp.get('objects').forEach(domain => {
        dispatch(getUpdateDomainSuccessAction(domain));
      });
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('updateDomain.success.message'),
        type: 'success'
      });
    }, error => {
      dispatch(getUpdateDomainFailedAction());
      FloatingAlertStore.addAlert({
        message: getEditDomainFailedMessage(getErrorType(error), getErrorTokens(error)),
        titleText: getI18nApiWarning('updateDomain.failed.title'),
        type: 'danger'
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}

/**
 * "Hydrates" EditDomain state when a user first opens the "Edit" domain dropdown
 *
 * Prefills state with:
 * 1. Domain ID
 * 2. Domain Usage Type for this row (b/c 1 domain can host >2 usage types)
 * 3. Relevant existing DomainUsageTypes that should start off staged
 */
export function openEditDropdown({
  domainId,
  editDropdownDomainUsageType,
  stagedDomainUsageTypes
}) {
  return {
    type: ActionTypes.SELECT_DOMAIN,
    domainId,
    editDropdownDomainUsageType,
    stagedDomainUsageTypes
  };
}
export function closeEditDropdown() {
  return {
    type: ActionTypes.DESELECT_DOMAIN
  };
}
export function updateDomainTeamsPending() {
  return {
    type: ActionTypes.UPDATE_DOMAIN_TEAMS_ATTEMPTED
  };
}
export function updateDomainTeamsSucceeded() {
  return {
    type: ActionTypes.UPDATE_DOMAIN_TEAMS_SUCCEEDED
  };
}
export function updateDomainTeamsFailed() {
  return {
    type: ActionTypes.UPDATE_DOMAIN_TEAMS_FAILED
  };
}
export function updateDomainTeams(domainId, teamIds) {
  return dispatch => {
    dispatch(updateDomainTeamsPending());
    Domains.updateDomainTeams(domainId, teamIds).then(() => {
      dispatch(updateDomainTeamsSucceeded());
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('updateDomain.success.message'),
        type: 'success'
      });
    }, () => {
      dispatch(updateDomainTeamsFailed());
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('updateDomain.failed.message'),
        titleText: getI18nApiWarning('updateDomain.failed.title'),
        type: 'danger'
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function fetchDnsRecordsByDomain(domainName) {
  return dispatch => {
    dispatch({
      type: ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_ATTEMPTED
    });
    return DomainDnsRecordsApi.fetchDnsRecordsByDomain(domainName).then(dnsRecords => {
      dispatch({
        type: ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_SUCCEEDED,
        dnsRecords
      });
    }, () => {
      dispatch({
        type: ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_FAILED
      });
    });
  };
}
export function validateReverseProxyDomain(domainName) {
  return dispatch => {
    dispatch({
      type: ActionTypes.ReverseProxyActions.testsStarted
    });
    return ReverseProxyApi.valideReverseProxyDomain(domainName).then(response => {
      dispatch({
        type: ActionTypes.ReverseProxyActions.testsSucceeded,
        failedChecks: response.failedChecks,
        passingChecks: response.passingChecks
      });
    }, () => {
      dispatch({
        type: ActionTypes.ReverseProxyActions.testsFailed
      });
    });
  };
}