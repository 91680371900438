'use es6';

import http from 'hub-http/clients/apiClient';
import Immutable from 'immutable';
import getEarlyRequesterResultPromise from '../../earlyRequester/getEarlyRequesterResultPromise';
import { withRateLimiting } from '../utils';
const API_PATH = 'email-mta-sending/v1/auth-domains';
export const addEmailDomain = withRateLimiting(domain => {
  return http.put(`${API_PATH}/${domain}`, {
    timeout: 30000
  }).then(Immutable.fromJS);
});
export function getEmailDomains() {
  const makeRequest = () => http.get(API_PATH);
  const quickFetchPromise = getEarlyRequesterResultPromise('emailDomains');
  if (quickFetchPromise) {
    return quickFetchPromise.catch(makeRequest).then(Immutable.fromJS);
  }
  return makeRequest().then(Immutable.fromJS);
}
export function getEmailDomain(domainName) {
  return http.put(`${API_PATH}/${domainName}/persist-if-exists`).then(Immutable.fromJS);
}
export function deleteEmailDomain(domain) {
  return http.delete(`${API_PATH}/${domain}`).then(Immutable.fromJS);
}
export function getDomainDnsRecords(domain) {
  return http.get(`${API_PATH}/${domain}`).then(Immutable.fromJS);
}
export function getSpfRecords() {
  return http.get(`${API_PATH}/example.com/requirements`).then(Immutable.fromJS);
}