import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as usersClient from 'ContentData/api/Users';
import { USERS_FETCH_USERS, USERS_FETCH_USERS_FOR_TEAMS, USERS_UPDATE_USERS_FOR_TEAMS } from 'ContentData/actions/ActionTypes';
const fetchUsersAction = createGenericFetchAction(USERS_FETCH_USERS, usersClient.getUsersCanNotify);
export const fetchUsers = () => {
  return fetchUsersAction();
};
export const fetchUsersForTeams = createGenericFetchAction(USERS_FETCH_USERS_FOR_TEAMS, usersClient.getUsers);
export const updateUsersForTeams = data => dispatch => dispatch({
  type: USERS_UPDATE_USERS_FOR_TEAMS,
  data
});