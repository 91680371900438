import { changeRecord } from 'domain-connect-lib/synchronousDomainConnect';
import { bulkFetchSyncFlowUrls } from '../../utils/domains/synchronousDomainConnect';
import { RequestStatus } from '../../Constants';
import { SynchronousDomainConnectActions } from '../ActionTypes';
export function getDnsRecordUrlsAttemptedAction() {
  return {
    type: SynchronousDomainConnectActions.getDnsRecordUrlsAttempted
  };
}
export function getDnsRecordUrlsSucceededAction(syncFlowUrlsMap) {
  return {
    type: SynchronousDomainConnectActions.getDnsRecordUrlsSucceeded,
    syncFlowUrlsMap
  };
}
export function getDnsRecordUrlsFailedAction() {
  return {
    type: SynchronousDomainConnectActions.getDnsRecordUrlsFailed
  };
}
export function getDnsRecordUrlsForSslPreProvisioningFailedAction() {
  return {
    type: SynchronousDomainConnectActions.getDnsRecordUrlsForSslPreProvisioningFailed
  };
}
export function getDnsRecordUrls(changeTypes) {
  return dispatch => {
    dispatch(getDnsRecordUrlsAttemptedAction());
    bulkFetchSyncFlowUrls(changeTypes).then(syncFlowUrlsMap => {
      dispatch(getDnsRecordUrlsSucceededAction(syncFlowUrlsMap));
    }).catch(() => {
      dispatch(getDnsRecordUrlsFailedAction());
    });
  };
}
export function getDnsRecordUrlsForSslPreProvisioning(changeTypes) {
  return dispatch => {
    dispatch(getDnsRecordUrlsAttemptedAction());
    bulkFetchSyncFlowUrls(changeTypes).then(syncFlowUrlsMap => {
      dispatch(getDnsRecordUrlsSucceededAction(syncFlowUrlsMap));
    }).catch(() => {
      dispatch(getDnsRecordUrlsForSslPreProvisioningFailedAction());
    });
  };
}
export function updateRecordStatus(domainChangeId, status) {
  return {
    type: SynchronousDomainConnectActions.updateRecordStatus,
    domainChangeId,
    status
  };
}
export function openSyncFlowUrl(domainChangeId, syncFlowUrl) {
  return dispatch => {
    dispatch(updateRecordStatus(domainChangeId, RequestStatus.PENDING));
    changeRecord(domainChangeId, syncFlowUrl).then(() => {
      dispatch(updateRecordStatus(domainChangeId, RequestStatus.SUCCEEDED));
    }).catch(() => {
      dispatch(updateRecordStatus(domainChangeId, RequestStatus.FAILED));
    });
  };
}