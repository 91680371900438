const NO_ISSUES = 'NO_ISSUES';
const UNKNOWN_ISSUES = 'UNKNOWN_ISSUES';
const CAA_RECORD_CONFLICT = 'CAA_RECORD_CONFLICT';
const HUBSPOT_OUT_OF_SYNC_WITH_CLOUDFLARE = 'HUBSPOT_OUT_OF_SYNC_WITH_CLOUDFLARE';
const VALIDATION_TIMED_OUT = 'VALIDATION_TIMED_OUT';
const INCORRECT_DNS_RECORD = 'INCORRECT_DNS_RECORD';
const INCORRECT_HTTP_VALIDATION_RESPONSE = 'INCORRECT_HTTP_VALIDATION_RESPONSE';
const REVERSE_PROXY_VALIDATION_FAILED = 'REVERSE_PROXY_VALIDATION_FAILED';
const BLOCKED_DOMAIN = 'BLOCKED_DOMAIN';
const DELETED_HOSTNAME = 'DELETED_HOSTNAME';
const CLOUDFLARE_VALIDATION_ERROR = 'CLOUDFLARE_VALIDATION_ERROR';
const SslIssueTypes = {
  CLOUDFLARE_VALIDATION_ERROR,
  DELETED_HOSTNAME,
  BLOCKED_DOMAIN,
  VALIDATION_TIMED_OUT,
  HUBSPOT_OUT_OF_SYNC_WITH_CLOUDFLARE,
  CAA_RECORD_CONFLICT,
  UNKNOWN_ISSUES,
  NO_ISSUES,
  REVERSE_PROXY_VALIDATION_FAILED,
  INCORRECT_HTTP_VALIDATION_RESPONSE,
  INCORRECT_DNS_RECORD
};
export default SslIssueTypes;