import ReverseProxyCheckTypeEnum from '../../enums/domains/ReverseProxyTest';
export default (originalChecks => {
  const orderedChecks = [];
  if (!originalChecks || originalChecks.size === 0) {
    return orderedChecks;
  }
  Object.keys(ReverseProxyCheckTypeEnum).forEach(checkName => {
    const check = originalChecks.get(checkName);
    if (check) {
      orderedChecks.push(check);
    }
  });
  return orderedChecks;
});