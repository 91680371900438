import Immutable from 'immutable';
import http from 'hub-http/clients/apiClient';
const DEDICATED_IP_URI = 'dedicated-ip-setup/v1';
const ACCOUNT_TYPE_PATH = {
  DEDICATED_MARKETING: 'dedicated-marketing',
  DEDICATED_TRANSACTIONAL: 'dedicated-transactional'
};
export default class DedicatedIPAPI {}
DedicatedIPAPI.addDedicatedIP = (accountType, domainName) => {
  const accountTypePath = ACCOUNT_TYPE_PATH[accountType];
  return http.post(`${DEDICATED_IP_URI}/account-setup/${accountTypePath}`, {
    data: {
      domainName
    }
  }).then(Immutable.fromJS);
};