'use es6';

import { createSelector } from 'reselect';
import I18n from 'I18n';
import { getSomeTeamsAreAvailableInHierarchy, getTeams } from 'ContentData/selectors/teams';
import { canAssignTeamsAsNonAdmin } from 'ContentUtils/helpers/TeamsHelpers';
import { DomainType, DomainUsageTypes, EditDomainDropdownActionTypesToGroups } from '../../Constants';
import { isContentHostingDomainResolving, isSecondaryDomain, getDoesDomainHostMoreThanOneSupportedUsageType, isDomainInternalOrHasBrandId, isWwwSubdomain, isApexDomain, isHubSpotPageBuilderDomain, isHsSitesDomain, getIsPageBuilderDomainAvailable } from '../../utils/domains/Domains';
import { getAreInternalDomainsShowing, getIsAnyContentTypeUnassignedToPrimary, getPortalHasMultipleHsSitesDomains, getResolvingAndManuallyMarkedDomains, getSupportedDomainUsageTypeForHsSites, getSupportedDomainUsageTypesForCustomDomain, getSupportedDomainUsageTypesForSecondary, getDoAllInScopeContentTypesSupportPageBuilder } from './Domains';
import { getHasTeamsAssignmentScope, getHasTeamsContentPartioningScope, getPortalHasMultiDomainHostingScope, getIsUserReadOnly, getHasDnsRecordsReadScope } from '../Auth';
import { EditDomainDropdownActionTypes } from '../../enums/domains/EditDomainDropdownActionTypes';
import { getIsUngatedForSslProvisioningTool } from '../Auth';
export const getDomainId = state => state.editDomain.get('domainId');
export const getEditDropdownDomainUsageType = state => state.editDomain.get('editDropdownDomainUsageType');
export const getManuallyMarkedAsResolving = state => state.editDomain.get('manuallyMarkedAsResolving');
export const getRequireHttps = state => state.editDomain.get('requireHttps');
export const getUpdateRequestStatus = state => state.editDomain.get('updateRequestStatus');
export const getStagedDomainUsageTypes = state => state.editDomain.get('stagedDomainUsageTypes');
export const getCanAssignTeams = (state, props) => {
  let teamIds = props.domain.get('teamIds');
  teamIds = teamIds && teamIds.toArray() || [];
  return createSelector([getHasTeamsAssignmentScope, getHasTeamsContentPartioningScope, getTeams, getSomeTeamsAreAvailableInHierarchy(teamIds)], (hasTeamsAssignmentScope, hasTeamsContentScope, teams, someDomainTeamIsAvailableToUser) => {
    if (!hasTeamsContentScope) {
      return false;
    }
    if (hasTeamsAssignmentScope) {
      return true;
    }
    // Also need to check if teams are in hierarchy because Domains API
    // doesn't filter out the domains unavailable to user like content does
    return someDomainTeamIsAvailableToUser && canAssignTeamsAsNonAdmin(teamIds, [], teams);
  })(state);
};
export const getValidReplacementDomains = createSelector([getResolvingAndManuallyMarkedDomains, getSupportedDomainUsageTypeForHsSites, getAreInternalDomainsShowing, getPortalHasMultiDomainHostingScope, getDoAllInScopeContentTypesSupportPageBuilder, (state, props) => props.domain, (state, props) => props.domainUsageType], (resolvingAndManuallyMarkedDomains, supportedDomainUsageTypeForHsSites, areInternalDomainsShowing, portalHasMultiDomainHostingScope, doAllInScopeContentTypesSupportPageBuilder, rowDomain, domainUsageType) => {
  return resolvingAndManuallyMarkedDomains.filter(replacementDomain => {
    const isDifferentDomain = replacementDomain.get('id') !== rowDomain.get('id');
    const isValidCustomDomain = replacementDomain.get('brandId');
    const isPageBuilderDomainAvailable = portalHasMultiDomainHostingScope ? getIsPageBuilderDomainAvailable(domainUsageType, supportedDomainUsageTypeForHsSites) : doAllInScopeContentTypesSupportPageBuilder;
    const isHsSitesDomainAvailable = !!supportedDomainUsageTypeForHsSites && supportedDomainUsageTypeForHsSites.includes(domainUsageType);
    const isInternalDomainInScope = isHubSpotPageBuilderDomain(replacementDomain) && isPageBuilderDomainAvailable || isHsSitesDomain(replacementDomain) && isHsSitesDomainAvailable;
    const isValidInternalDomain = replacementDomain.get('isInternalDomain') && areInternalDomainsShowing && isInternalDomainInScope;
    return isDifferentDomain && (isValidCustomDomain || isValidInternalDomain);
  });
});
const getValidEditDomainDropdownActions = createSelector([getIsUserReadOnly, getHasDnsRecordsReadScope, getSupportedDomainUsageTypesForSecondary, getPortalHasMultiDomainHostingScope, getIsAnyContentTypeUnassignedToPrimary, getCanAssignTeams, getSupportedDomainUsageTypesForCustomDomain, getPortalHasMultipleHsSitesDomains, getDoAllInScopeContentTypesSupportPageBuilder, getValidReplacementDomains, getIsUngatedForSslProvisioningTool, (state, props) => props.domain, (state, props) => props.domainType, (state, props) => props.domainUsageType], (isUserReadOnly, hasDnsRecordsReadScope, supportedDomainUsageTypesForSecondary, portalHasMultiDomainHostingScope, isAnyContentTypeUnassignedToPrimary, canAssignTeams, supportedDomainUsageTypesForCustomDomain, portalHasMultipleHsSitesDomains, doAllInScopeContentTypesSupportPageBuilder, validReplacementDomains, isUngatedForSslProvisioningTool, domain, domainType, domainUsageType) => {
  const shouldIncludeShowDnsRecords = !domain.get('isInternalDomain');
  if (isUserReadOnly) {
    if (hasDnsRecordsReadScope && shouldIncludeShowDnsRecords) {
      return [EditDomainDropdownActionTypes.SHOW_DNS_RECORDS];
    }
    return [];
  }
  const supportsWebsiteDomainUsageType = supportedDomainUsageTypesForCustomDomain.includes(DomainUsageTypes.WEBSITE);
  const validActions = [];
  if ((domainType === DomainType.PRIMARY || domainType === DomainType.SECONDARY) && !isContentHostingDomainResolving(domain)) {
    validActions.push(EditDomainDropdownActionTypes.SET_PUBLISHING_STATE);
  }
  if ([DomainType.REDIRECT, DomainType.DOWNGRADED].includes(domainType) && !isHubSpotPageBuilderDomain(domain)) {
    validActions.push(EditDomainDropdownActionTypes.REPLACE_REDIRECT_TO_DOMAIN);
  }
  if (domainType === DomainType.PRIMARY && isAnyContentTypeUnassignedToPrimary && domain.get('isResolving') && !isHubSpotPageBuilderDomain(domain) || domainType === DomainType.SECONDARY && !!supportedDomainUsageTypesForSecondary.length && !isHubSpotPageBuilderDomain(domain)) {
    validActions.push(EditDomainDropdownActionTypes.ADD_CONTENT_TYPES);
  }
  const validReplacementDomainsAvailable = !!validReplacementDomains && validReplacementDomains.size > 0;
  if (domainType === DomainType.PRIMARY && !!supportedDomainUsageTypesForCustomDomain && supportedDomainUsageTypesForCustomDomain.includes(domainUsageType) && validReplacementDomainsAvailable) {
    validActions.push(EditDomainDropdownActionTypes.REPLACE);
  }
  if (domainType === DomainType.PRIMARY && !!supportedDomainUsageTypesForSecondary.length && !isSecondaryDomain(domain) && !isHubSpotPageBuilderDomain(domain)) {
    validActions.push(EditDomainDropdownActionTypes.MAKE_PRIMARY_ALSO_SECONDARY);
  }

  // PageBuilder domains will always have HTTPS/SSL set to true (see #3548)
  if (!isHubSpotPageBuilderDomain(domain)) {
    validActions.push(EditDomainDropdownActionTypes.EDIT_DOMAIN_SECURITY);
  }
  const canMakePageBuilderPrimary = portalHasMultiDomainHostingScope || doAllInScopeContentTypesSupportPageBuilder;
  const canMakeSecondaryPrimary = domainType === DomainType.SECONDARY && !domain.get('isAnyPrimary');
  const canMakeRedirectOrDowngradedPrimary = [DomainType.REDIRECT, DomainType.DOWNGRADED].includes(domainType) && !isApexDomain(domain) && isDomainInternalOrHasBrandId(domain) && (supportsWebsiteDomainUsageType || !isWwwSubdomain(domain));
  if (canMakeSecondaryPrimary || canMakeRedirectOrDowngradedPrimary) {
    if (isAnyContentTypeUnassignedToPrimary && domain.get('isResolving') && (!isHubSpotPageBuilderDomain(domain) || canMakePageBuilderPrimary)) {
      validActions.push(EditDomainDropdownActionTypes.MAKE_PRIMARY);
    }
  }
  if ([DomainType.REDIRECT, DomainType.DOWNGRADED].includes(domainType) && !isApexDomain(domain) && isDomainInternalOrHasBrandId(domain) && !!supportedDomainUsageTypesForSecondary.length && (supportsWebsiteDomainUsageType || !isWwwSubdomain(domain))) {
    validActions.push(EditDomainDropdownActionTypes.MAKE_REDIRECT_SECONDARY);
  }
  if ((domainType === DomainType.PRIMARY || domainType === DomainType.SECONDARY) && canAssignTeams) {
    validActions.push(EditDomainDropdownActionTypes.ASSIGN_TEAMS);
  }
  if (domain.get('isUsedForReverseProxy')) {
    validActions.push(EditDomainDropdownActionTypes.VALIDATE_REVERSE_PROXY_DOMAIN);
  }
  if ([DomainType.REDIRECT, DomainType.DOWNGRADED].includes(domainType) && !isDomainInternalOrHasBrandId(domain)) {
    validActions.push(EditDomainDropdownActionTypes.REPLACE_BRAND);
  }
  if (shouldIncludeShowDnsRecords) {
    validActions.push(EditDomainDropdownActionTypes.SHOW_DNS_RECORDS);
  }
  if (getDoesDomainHostMoreThanOneSupportedUsageType(domain, supportedDomainUsageTypesForCustomDomain) && portalHasMultiDomainHostingScope) {
    validActions.push(EditDomainDropdownActionTypes.REMOVE_CONTENT_TYPE);
  } else if (!domain.get('isInternalDomain') || portalHasMultipleHsSitesDomains && !isHubSpotPageBuilderDomain(domain)) {
    validActions.push(EditDomainDropdownActionTypes.REMOVE);
  }
  if (isUngatedForSslProvisioningTool && isContentHostingDomainResolving(domain)) {
    validActions.push(EditDomainDropdownActionTypes.VERIFY_SSL_CERTIFICATE);
  }
  return validActions;
});
export const getValidEditDomainDropdownActionsAndLabelsByGroup = createSelector([getValidEditDomainDropdownActions, (state, props) => props.domain], (validEditDomainDropdownActions, domain) => {
  // Compute the translated text for each action
  const validEditDomainDropdownActionsAndLabels = validEditDomainDropdownActions.map(action => {
    let label = I18n.text(`settings-ui-domains.editDomain.dropdownActions.${action}`);
    if (action === EditDomainDropdownActionTypes.SET_PUBLISHING_STATE) {
      label = domain.get('manuallyMarkedAsResolving') ? I18n.text(`settings-ui-domains.editDomain.dropdownActions.SET_PUBLISHING_STATE.editState`) : I18n.text(`settings-ui-domains.editDomain.dropdownActions.SET_PUBLISHING_STATE.setAsReady`);
    }
    return {
      action,
      label
    };
  });

  // Sort the dropdown actions in place according to their labels
  validEditDomainDropdownActionsAndLabels.sort((firstActionAndLabel, secondActionAndLabel) => {
    return firstActionAndLabel.label.localeCompare(secondActionAndLabel.label);
  });
  const groupsToActions = {};
  validEditDomainDropdownActionsAndLabels.forEach(actionAndLabel => {
    const groupNumber = EditDomainDropdownActionTypesToGroups[actionAndLabel.action];
    if (groupsToActions[groupNumber] === undefined) {
      groupsToActions[groupNumber] = [];
    }
    groupsToActions[groupNumber].push(actionAndLabel);
  });
  return groupsToActions;
});
const getDomainRecordsForSelectedDomain = state => {
  return state.editDomain.get('dsnRecordsForDomain');
};
export const getDomainDnsRecorFetchStatusForSelectedDomain = createSelector([getDomainRecordsForSelectedDomain], domainRecords => {
  return domainRecords.get('fetchStatus');
});
export const getDomainDnsRecordListForSelectedDomain = createSelector([getDomainRecordsForSelectedDomain], domainRecords => {
  return domainRecords.get('records');
});
const selectReverseProxyValidation = state => {
  return state.editDomain.get('reverseProxyCheckForDomain');
};
export const selectReverseProxyValidationRequestStatus = createSelector([selectReverseProxyValidation], reverseProxyCheck => {
  return reverseProxyCheck.fetchStatus;
});
export const selectReverseProxyValidationData = createSelector([selectReverseProxyValidation], reverseProxyCheck => {
  return reverseProxyCheck.checks;
});
const selectReverseProxyFailedChecksList = createSelector([selectReverseProxyValidation], reverseProxyCheck => {
  return reverseProxyCheck.failedChecks;
});
export const selectReverseProxyValidationFailedChecksCount = createSelector([selectReverseProxyFailedChecksList], reverseProxyFailedChecksList => {
  return reverseProxyFailedChecksList.length;
});