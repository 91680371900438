export const MIN_WIDTH_FOR_REVERSE_PROXY = 900;
export const MIN_WIDTH = 500;
export const WIDTH_MULTIPLIER = 250;
export const shouldIncreaseModalSizeForReverseProxy = ({
  isScopedForReverseProxyWrite,
  calculatedModalWidth,
  isUngatedForReverseProxySetup
}) => isScopedForReverseProxyWrite && calculatedModalWidth <= MIN_WIDTH_FOR_REVERSE_PROXY && isUngatedForReverseProxySetup;
export const calculateModalWidth = ({
  isScopedForReverseProxyWrite,
  isUngatedForReverseProxySetup,
  buttonsToRenderCount
}) => {
  let calculatedModalWidth = buttonsToRenderCount * WIDTH_MULTIPLIER;
  if (shouldIncreaseModalSizeForReverseProxy({
    isScopedForReverseProxyWrite,
    calculatedModalWidth,
    isUngatedForReverseProxySetup
  })) {
    calculatedModalWidth = MIN_WIDTH_FOR_REVERSE_PROXY;
  }
  if (!isScopedForReverseProxyWrite && buttonsToRenderCount <= 1) {
    calculatedModalWidth = MIN_WIDTH;
  }
  return calculatedModalWidth;
};
export const getModalBodyJustifyContentProp = ({
  isScopedForReverseProxyWrite,
  buttonsToRenderCount,
  isUngatedForReverseProxySetup
}) => {
  if (shouldIncreaseModalSizeForReverseProxy({
    isScopedForReverseProxyWrite,
    calculatedModalWidth: calculateModalWidth({
      isScopedForReverseProxyWrite,
      buttonsToRenderCount,
      isUngatedForReverseProxySetup
    }),
    isUngatedForReverseProxySetup
  })) {
    return 'center';
  } else {
    return 'between';
  }
};