/* hs-eslint ignored failing-rules */

'use es6';

import Raven from 'raven-js';
import Immutable from 'immutable';
import { markActionComplete } from 'user-context/onboarding';
import * as Domains from '../../api/domains/Domains';
import UsageTracker from '../../utils/usageTracker';
import { getIsDomainSetupComplete } from '../../utils/domains/getIsDomainSetupComplete';
import { getDomainsListAndDnsRecordsFromTask } from '../../utils/domains/setupTask';
import * as ActionTypes from '../ActionTypes';
import { updateVerificationEndingTime, updateFinalStepScreenAction } from './DomainWizard';
import { AddDomainWizardFinalStepScreens } from '../../Constants';
const getIsReponseEmpty = rawNetworkResponse => !(rawNetworkResponse && rawNetworkResponse.responseJSON);
const getErrorType = rawNetworkResponse => rawNetworkResponse.responseJSON.errorType;
const getErrorTokens = rawNetworkResponse => Immutable.fromJS(rawNetworkResponse.responseJSON.errorTokens);
export const getCustomError = rawNetworkResponse => {
  if (getIsReponseEmpty(rawNetworkResponse)) {
    return Immutable.Map({});
  }
  return Immutable.Map({
    errorType: getErrorType(rawNetworkResponse),
    tokens: getErrorTokens(rawNetworkResponse),
    status: rawNetworkResponse.status
  });
};
export function getVerifyTaskDomainsAttemptedAction() {
  return {
    type: ActionTypes.FETCH_VERIFY_TASK_DOMAINS_ATTEMPTED
  };
}
export function getVerifyTaskDomainsSucceededAction(domains, dnsRecords, reverseProxyValidationResponses) {
  return {
    type: ActionTypes.FETCH_VERIFY_TASK_DOMAINS_SUCCEEDED,
    domains,
    dnsRecords,
    reverseProxyValidationResponses
  };
}
export function getVerifyTaskDomainsFailedAction(customError) {
  return {
    type: ActionTypes.FETCH_VERIFY_TASK_DOMAINS_FAILED,
    customError
  };
}
export function resetVerifyTaskDomainsRequestStatus() {
  return {
    type: ActionTypes.RESET_VERIFY_TASK_DOMAINS_REQUEST_STATUS
  };
}
export function fetchTaskDomainsVerifyInfo(taskId, triggerNextCheck) {
  return dispatch => {
    // If we've never verified the domain before, show the user a loading screen
    if (triggerNextCheck) {
      dispatch(updateFinalStepScreenAction(AddDomainWizardFinalStepScreens.SHORT_LOADING));
    }
    dispatch(getVerifyTaskDomainsAttemptedAction());
    Domains.fetchTaskDomainsVerificationInfo(taskId, triggerNextCheck).then(resp => {
      const {
        domainsList: domains,
        dnsRecords,
        reverseProxyValidationResponses
      } = getDomainsListAndDnsRecordsFromTask(resp);
      const verificationEndingTime = resp.get('nextCheckAt');
      const success = domains.every(getIsDomainSetupComplete);
      UsageTracker.track('connectDomainInteraction', {
        action: 'clicked-verify-cname',
        taskId,
        success
      });
      const hasCustomConnectedSitePageDomain = domains.some(domain => domain.get('isUsedForSitePage') && domain.get('isConnectedCustomDomain'));
      // only complete task (and show completion modal) if a custom domain is connected for website pages
      if (hasCustomConnectedSitePageDomain) {
        markActionComplete('connect-your-domain-task');
      }
      dispatch(getVerifyTaskDomainsSucceededAction(domains, dnsRecords, reverseProxyValidationResponses));
      dispatch(updateVerificationEndingTime(verificationEndingTime));
    }, resp => {
      dispatch(getVerifyTaskDomainsFailedAction(getCustomError(resp)));
    }).catch(err => {
      Raven.captureException(err, {
        tags: {
          action: ActionTypes.FETCH_VERIFY_TASK_DOMAINS_FAILED
        }
      });
    });
  };
}