import { Map as ImmutableMap } from 'immutable';
import { VisibleDowngradeStatuses } from '../../enums/domains/DowngradeStatus';
import { VisibleDowngradeCategories } from '../../enums/domains/DowngradeCategories';
export default function getShouldShowDowngrade(downgrade) {
  if (!ImmutableMap.isMap(downgrade)) {
    return false;
  }
  const downgradeCategory = downgrade.get('downgradeCategory');
  const downgradeStatus = downgrade.get('downgradeStatus');
  return VisibleDowngradeCategories.includes(downgradeCategory) && !!downgradeStatus && VisibleDowngradeStatuses.includes(downgradeStatus);
}