/* hs-eslint ignored failing-rules */

'use es6';

import * as ActionTypes from '../ActionTypes';
import * as DomainAlertsApi from '../../api/domains/DomainAlerts';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { getI18nApiWarning } from '../../utils/domains/langUtils';
export function getDeleteDomainAlertAction(alertId) {
  return {
    type: ActionTypes.DELETE_DOMAIN_ALERT,
    alertId
  };
}
export function deleteDomainAlert(domainId, alertId) {
  return dispatch => {
    DomainAlertsApi.deleteDomainAlert(domainId, alertId).then(() => dispatch(getDeleteDomainAlertAction(alertId)), () => {
      FloatingAlertStore.addAlert({
        titleText: getI18nApiWarning('deleteDomainAlert.failed.title'),
        message: getI18nApiWarning('deleteDomainAlert.failed.message'),
        type: 'danger'
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}