import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { DomainConnectMessages } from './Constants';
import { openWindow } from './shared';
export function createRedirectUri(domainChangeId) {
  const portalId = PortalIdParser.get();
  const hostname = getFullUrl('app');
  const uri = `${hostname}/domain-connect/${portalId}?authedEmail=${domainChangeId}`;
  const encodedURI = encodeURIComponent(uri);
  return encodedURI;
}
function getIsMessageForCorrectDomain(event, domainChangeId) {
  const hostname = window.location.hostname;
  if (event.origin !== `https://${hostname}`) {
    return false;
  }
  if (!event.data.payload || !event.data.payload.domainName) {
    return false;
  }
  const {
    payload
  } = event.data;
  return payload.domainName === domainChangeId;
}
export function changeRecord(domainChangeId, url) {
  openWindow(url);
  return new Promise((resolve, reject) => {
    const receiveMessage = event => {
      if (!getIsMessageForCorrectDomain(event, domainChangeId)) {
        return;
      }
      const {
        payload
      } = event.data;
      switch (payload.message) {
        case DomainConnectMessages.SUCCEEDED:
          resolve();
          break;
        case DomainConnectMessages.FAILED:
        default:
          reject(payload.error);
      }
      window.removeEventListener('message', receiveMessage);
    };
    window.addEventListener('message', receiveMessage, false);
  });
}