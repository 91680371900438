import SslIssueTypes from '../../enums/SslIssueTypes';
const DEFAULT_ISSUE_TYPE = {
  type: SslIssueTypes.UNKNOWN_ISSUES
};
const formatVerifyDomainSslResponse = data => {
  const issue = data ? data.issue : DEFAULT_ISSUE_TYPE;
  if (issue === undefined || issue.type === SslIssueTypes.NO_ISSUES) {
    return {
      isSslValid: true
    };
  }
  if (issue.type === SslIssueTypes.INCORRECT_DNS_RECORD || issue.type === SslIssueTypes.CAA_RECORD_CONFLICT) {
    const missingDnsRecordListFromNetwork = data && data.fixes ? data.fixes : [];
    const missingRecordList = missingDnsRecordListFromNetwork.map(fix => {
      return {
        expectedRecord: fix.expectedRecord,
        recordType: fix.recordType
      };
    });
    return {
      isSslValid: false,
      sslIssue: {
        type: issue.type,
        missingRecordList
      }
    };
  }
  if (Object.values(SslIssueTypes).includes(issue.type)) {
    return {
      isSslValid: false,
      sslIssue: {
        type: issue.type
      }
    };
  }
  return {
    isSslValid: false,
    sslIssue: {
      type: SslIssueTypes.UNKNOWN_ISSUES
    }
  };
};
export default formatVerifyDomainSslResponse;