'use es6';

import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { getI18nApiWarning } from './langUtils';
export default function displayFetchSecuritySettingsFailedAlert() {
  FloatingAlertStore.addAlert({
    titleText: getI18nApiWarning('fetchSecuritySettings.failed.title'),
    message: getI18nApiWarning('fetchSecuritySettings.failed.message'),
    type: 'danger'
  });
}