import { List } from 'immutable';
export function getDomainsListAndDnsRecordsFromTask(resp) {
  const taskDomains = resp.get('domains');
  let siblingDomains = List();
  const taskDnsRecords = resp.get('dnsRecords');
  let siblingDnsRecords = List();
  const reverseProxyValidationResponses = resp.get('reverseProxyValidationResponses');
  const siblingTask = resp.get('siblingTask');
  if (siblingTask) {
    siblingDomains = siblingTask.get('domains');
    siblingDnsRecords = siblingTask.get('dnsRecords');
  }
  const domainsList = taskDomains.concat(siblingDomains).toList();
  const dnsRecords = taskDnsRecords.concat(siblingDnsRecords).toList();
  return {
    domainsList,
    dnsRecords,
    reverseProxyValidationResponses
  };
}