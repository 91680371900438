// https://git.hubteam.com/HubSpot/cos-domains/blob/master/ContentDomainsData/src/main/java/com/hubspot/content/domains/data/downgrades/DowngradeCategory.java

export const BRAND_DOMAINS = 'BRAND_DOMAINS';
export const SECONDARY_DOMAINS = 'SECONDARY_DOMAINS';
export const PRIMARY_DOMAINS = 'PRIMARY_DOMAINS';
export const DYNAMIC_CONTENT_DOMAINS = 'DYNAMIC_CONTENT_DOMAINS';
export const APEX_HOSTING_DOMAINS = 'APEX_HOSTING_DOMAINS';
export const APEX_REDIRECT_DOMAINS = 'APEX_REDIRECT_DOMAINS';
export const MULTI_CUSTOM_DOMAINS = 'MULTI_CUSTOM_DOMAINS';
export const WWW_SUBDOMAIN = 'WWW_SUBDOMAIN';
export const VisibleDowngradeCategories = [BRAND_DOMAINS, SECONDARY_DOMAINS, PRIMARY_DOMAINS, APEX_REDIRECT_DOMAINS, MULTI_CUSTOM_DOMAINS, WWW_SUBDOMAIN];