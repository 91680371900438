'use es6';

import * as ActionTypes from '../ActionTypes';
import * as DomainSecuritySettingsApi from '../../api/domains/DomainSecuritySettings';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { getI18nApiWarning } from '../../utils/domains/langUtils';
import { getStagedChanges, getOriginalSettings } from '../../selectors/domains/DomainSecuritySettings';
import getUpdateDomainSettingsNetworkPayload from '../../utils/domains/getUpdateDomainSettingsNetworkPayload';
import displayFetchSecuritySettingsFailedAlert from '../../utils/domains/displayFetchSecuritySettingsFailedAlert';
export function getFetchSecuritySettingsSucceededAction(securitySettings) {
  return {
    type: ActionTypes.FETCH_SECURITY_SETTINGS_SUCCEEDED,
    securitySettings
  };
}
export function getFetchSecurituSettingsAttemptedAction(domainId) {
  return {
    type: ActionTypes.FETCH_SECURITY_SETTINGS_ATTEMPTED,
    domainId
  };
}
export function getFetchSecurituSettingsFailedAction() {
  return {
    type: ActionTypes.FETCH_SECURITY_SETTINGS_FAILED
  };
}
export function getUpdateSecuritySettingsSucceededAction(securitySettings) {
  return {
    type: ActionTypes.UPDATE_SECURITY_SETTINGS_SUCCEEDED,
    securitySettings
  };
}
export function getUpdateSecuritySettingsAttemptedAction(domainId) {
  return {
    type: ActionTypes.UPDATE_SECURITY_SETTINGS_ATTEMPTED,
    domainId
  };
}
export function getUpdateSecuritySettingsFailedAction() {
  return {
    type: ActionTypes.UPDATE_SECURITY_SETTINGS_FAILED
  };
}
export function fetchDomainSecuritySettings(domainId) {
  return dispatch => {
    dispatch(getFetchSecurituSettingsAttemptedAction(domainId));
    DomainSecuritySettingsApi.fetchDomainSecuritySettings(domainId).then(securitySettings => {
      dispatch(getFetchSecuritySettingsSucceededAction(securitySettings));
    }).catch(() => {
      dispatch(getFetchSecurituSettingsFailedAction());
      displayFetchSecuritySettingsFailedAlert();
    });
  };
}
export function removeStagedSecurityHeader(securityHeader) {
  return dispatch => {
    dispatch({
      type: ActionTypes.REMOVE_STAGED_SECURITY_SETTING,
      securityHeader
    });
  };
}
export function initSecurityHeader(securityHeader, initialSecuritySettingValues) {
  return dispatch => {
    dispatch({
      type: ActionTypes.INIT_SECURITY_SETTING,
      securityHeader,
      initialSecuritySettingValues
    });
  };
}
export function updateStagedSecuritySetting(securityHeader, updates) {
  return dispatch => {
    dispatch({
      type: ActionTypes.UPDATE_STAGED_SECURITY_SETTING,
      securityHeader,
      updates
    });
  };
}
export function updateDomainSecuritySettings(domainId, domainName) {
  return (dispatch, getState) => {
    const state = getState();
    const stagedSettings = getStagedChanges(state);
    const originalSettings = getOriginalSettings(state);
    dispatch(getUpdateSecuritySettingsAttemptedAction());
    DomainSecuritySettingsApi.updateSettings(domainId, getUpdateDomainSettingsNetworkPayload(stagedSettings, originalSettings)).then(() => {
      dispatch(getUpdateSecuritySettingsSucceededAction());
      FloatingAlertStore.addAlert({
        titleText: getI18nApiWarning('updateSecuritySettings.success.title', {
          domainName
        }),
        type: 'success'
      });
    }, () => {
      dispatch(getUpdateSecuritySettingsFailedAction());
      FloatingAlertStore.addAlert({
        titleText: getI18nApiWarning('updateSecuritySettings.failed.title'),
        message: getI18nApiWarning('updateSecuritySettings.failed.message'),
        type: 'danger'
      });
    }).catch(err => {
      throw err;
    });
  };
}