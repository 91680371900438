'use es6';

import Raven from 'raven-js';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import allSettled from 'hs-promise-utils/allSettled';
import * as Domains from '../../api/domains/Domains';
import { AddDomainWizardFinalStepScreens, DEFAULT_DOMAINS_PRIMARY_LANGUAGE, DEFAULT_DOMAINS_SUFFIX, DEFAULT_EMPTY_SUBDOMAIN, DomainSetupTaskCategories, ValidationState, DomainType } from '../../Constants';
import * as VerifyDomainTypes from '../../enums/domains/VerifyDomainTypes';
import { history } from '../../utils/createHistory';
import { calculateTrackingSubdomainValue } from '../../utils/domains/automaticRedirect';
import { getSubdomainFromInput } from '../../utils/domains/Brands';
import { getDomainIdAsString, getDomainValidationErrorType, getDomainValidationSuffix, getNewDomainsAndNewDomainIdsFromResponse, getNumDomainsInAddDomainPayload, getUniqueDomainNamesToHostedUsageTypesMap, isApexDomain } from '../../utils/domains/Domains';
import { getDomainReplacementMap } from '../../utils/domains/domainWizard';
import getDomainValidationErrorList from '../../utils/domains/getDomainValidationErrorList';
import { getAddDomainFailedNotification, getTaskDomainsFetchSuccessNoDomainsFound } from '../../utils/domains/langUtils';
import { getDomainsListAndDnsRecordsFromTask } from '../../utils/domains/setupTask';
import { getBrandNameFromAddDomainResponse } from '../../utils/domains/getBrandNameFromAddDomainResponse';
import { navigateToDomainListing } from '../../utils/domains/urlUtils';
import UsageTracker from '../../utils/usageTracker';
import { getIsAllowedToAddApexRedirectDomains } from '../../selectors/Auth';
import { getBrands } from '../../selectors/domains/Brands';
import { getBrandNameToLastUsedSuffixMap } from '../../selectors/domains/Domains';
import { getDnsProvider, selectUseBuiltInCdn } from '../../selectors/domains/DomainWizard';
import { getAutomaticRedirect } from '../../selectors/domains/AutomaticRedirect';
import { getAddBrandFailureNotification } from '../../utils/domains/langUtils';
import * as ActionTypes from '../ActionTypes';
import { afterDelayApplyContentHostingDomainConnectTemplate } from './DomainConnect';
import { fetchBrandsByBusinessUnit, fetchDashboard, fetchDashboardByBusinessUnit, getAddBrandRequestSucceededAction } from './Domains';
import { getCustomError, getVerifyTaskDomainsAttemptedAction, getVerifyTaskDomainsFailedAction, getVerifyTaskDomainsSucceededAction } from './FetchTaskDomainsVerifyInfo';
import { getDnsProviderAttemptedAction, getDnsProviderFailedAction, getDnsProviderSucceededAction } from './shared';
export function getValidateDomainAttemptedAction() {
  return {
    type: ActionTypes.VALIDATE_DOMAIN_ATTEMPTED
  };
}
export function getValidateDomainSucceededAction(isApex, subdomain = DEFAULT_EMPTY_SUBDOMAIN, brandName, suffix) {
  return {
    type: ActionTypes.VALIDATE_DOMAIN_SUCCEEDED,
    isApex,
    subdomain,
    brandName,
    suffix
  };
}

/**
 *
 * @param {*} errorType
 * @param {*} errorList
 * @param {*} suffix
 * @returns
 */
export function getValidateDomainFailedAction(errorType, errorList = [], suffix) {
  return {
    type: ActionTypes.VALIDATE_DOMAIN_FAILED,
    errorList,
    errorType,
    suffix
  };
}
export function selectValidatedDomainNameAndChangePage(domainName, targetPagePath) {
  return (dispatch, getState) => {
    const state = getState();
    const [brandName, ...suffixes] = domainName.split('.');
    const suffix = suffixes.join('.') || getBrandNameToLastUsedSuffixMap(state).get(brandName, DEFAULT_DOMAINS_SUFFIX);
    history.push(targetPagePath);
    dispatch(getValidateDomainSucceededAction(true, '', brandName, suffix));

    // Stores last used or entered suffix in state + updates inputtedDomainName
    // Needs to go after dispatch above b/c this needs the updated brandName
    dispatch(updateAllSuffixPickerStrings(suffix));
  };
}

// Transitions from the Domain Name Input step --> Subdomain Picker
// If user entered a new domain name (vs. pick existing from dropdown)
export function verifyDomainNameInputAndChangePage(inputtedDomainName, targetPagePath) {
  return dispatch => {
    dispatch(getValidateDomainAttemptedAction());
    Domains.validateDomain(inputtedDomainName).then(resp => {
      if (resp.get('isApex') && !resp.get('supportCname')) {
        dispatch(getValidateDomainFailedAction(ValidationState.INVALID_DOMAIN, [ValidationState.CONFLICT_OTHER_SERVICE]));
      } else {
        history.push(targetPagePath);
        const brand = resp.get('brand');
        const suffix = resp.get('publicSuffix');
        const inputtedSubdomain = getSubdomainFromInput(inputtedDomainName, brand, suffix);

        // This will init ALL SubdomainPicker rows with the validated
        // subdomain, suffix, validation state, etc
        dispatch(getValidateDomainSucceededAction(!inputtedSubdomain, inputtedSubdomain, brand, suffix));
      }
    }, errorResponse => {
      dispatch(getValidateDomainFailedAction(getDomainValidationErrorType(errorResponse.responseJSON), getDomainValidationErrorList(errorResponse.responseJSON), getDomainValidationSuffix(errorResponse.responseJSON)));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}

/**
 * Validates all apex domain rows.
 * Will ONLY advance to the next step if all apex domain rows are valid.
 * (Assumes all subdomain rows validated via change handlers)
 *
 * For example:
 * - User selects an existing brand domain w/ apex domain(s) connected
 * - SubdomainPicker always begins in a potential "false positive" valid state
 *     - We don't actually validate each row - this avoids starting w/ errors
 *     - We only validate SubdomainPicker rows via change handlers
 * - If there's multiple apex domains (e.g. same brand, diff suffixes) we need to check them all!
 *
 * @param apexDomainRowKeys - domainRowKeys for all apex domains
 * @param brandName - shared by all rows
 * @param subdomainNames - map of {domainRowKey: subdomainName}
 * @param suffixes - map of {domainRowKey: suffixes}
 * @param targetPagePath - destination page
 * @returns
 */
export function verifyApexPickerRowsAndChangePage({
  apexDomainRowKeys,
  brandName,
  subdomainNames,
  suffixes,
  targetPagePath
}) {
  return dispatch => {
    dispatch(getValidateSubdomainPickerRowAttemptedAction());
    const apexDomainNames = apexDomainRowKeys.map(domainRowKey => `${brandName}.${suffixes.get(domainRowKey)}`);
    const uniqueApexDomainNames = [...new Set(apexDomainNames)];
    const uniqueDomainNamesToHostedUsageTypesMap = getUniqueDomainNamesToHostedUsageTypesMap({
      brandName,
      subdomainNames,
      suffixes
    });
    // Resolves when all API promises resolve
    allSettled(uniqueApexDomainNames.map(domainName => Domains.validateDomain(domainName))).then(responses => {
      responses.forEach(resp => {
        // Valid domain
        if (resp.status === 'fulfilled') {
          const successResp = resp.value;
          const validatedDomainName = `${successResp.get('brand')}.${successResp.get('publicSuffix')}`;
          const validatedDomainRowKeys = uniqueDomainNamesToHostedUsageTypesMap[validatedDomainName];
          validatedDomainRowKeys.forEach(domainRowKey => {
            return dispatch(getValidateSubdomainPickerRowSucceededAction({
              domainRowKey,
              validatedDomainName,
              isApex: successResp.get('isApex')
            }));
          });
        }
        // Invalid domain (resp.status === 'rejected')
        else {
          const errorResp = resp.reason.responseJSON;
          if (!!errorResp && !!errorResp.errorTokens) {
            const invalidDomainName = errorResp.errorTokens.domain[0];
            const invalidDomainRowKeys = uniqueDomainNamesToHostedUsageTypesMap[invalidDomainName];
            invalidDomainRowKeys.forEach(domainRowKey => {
              return dispatch(getValidateSubdomainPickerRowFailedAction({
                errorType: getDomainValidationErrorType(errorResp),
                errorList: getDomainValidationErrorList(errorResp),
                domainRowKey,
                validatedDomainName: invalidDomainName
              }));
            });
          } else {
            setTimeout(() => {
              throw new Error("Validate domain response can't be parsed");
            });
          }
        }
      });

      // Only advance to the next step if all apex domains are valid!
      if (responses.every(resp => resp.status === 'fulfilled')) {
        history.push(targetPagePath);
      }
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function verifyEmailSendingDomainNameAndChangePage(inputtedDomainName, locationObj) {
  return dispatch => {
    dispatch(getValidateDomainAttemptedAction());
    Domains.validateDomain(inputtedDomainName, VerifyDomainTypes.EMAIL_SENDING).then(resp => {
      history.push(locationObj);
      dispatch(getValidateDomainSucceededAction(resp.get('isApex')));
    }, response => {
      dispatch(getValidateDomainFailedAction(getDomainValidationErrorType(response.responseJSON), getDomainValidationErrorList(response.responseJSON), getDomainValidationSuffix(response.responseJSON)));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function verifyFullDomainNameAndChangePage(inputtedDomainName, targetPage, domainType = VerifyDomainTypes.CONTENT_HOSTING) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(getValidateDomainAttemptedAction());
    Domains.validateDomain(inputtedDomainName, domainType).then(resp => {
      if (resp.get('isApex') && resp.get('supportCname') && !getIsAllowedToAddApexRedirectDomains(state)) {
        dispatch(getValidateDomainFailedAction(ValidationState.INVALID_DOMAIN, [ValidationState.FULL_URL_REQUIRED]));
      } else if (resp.get('isApex') && !resp.get('supportCname')) {
        dispatch(getValidateDomainFailedAction(ValidationState.INVALID_DOMAIN, [ValidationState.CONFLICT_OTHER_SERVICE]));
      } else {
        history.push(targetPage);
        dispatch(getValidateDomainSucceededAction(false));
      }
    }, response => {
      dispatch(getValidateDomainFailedAction(getDomainValidationErrorType(response.responseJSON), getDomainValidationErrorList(response.responseJSON), getDomainValidationSuffix(response.responseJSON)));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getValidateSubdomainPickerRowAttemptedAction() {
  return {
    type: ActionTypes.VALIDATE_SUBDOMAIN_PICKER_ROW_ATTEMPTED
  };
}
export function getValidateSubdomainPickerRowFailedAction({
  errorType,
  errorList,
  domainRowKey,
  validatedDomainName
}) {
  return {
    type: ActionTypes.VALIDATE_SUBDOMAIN_PICKER_ROW_FAILED,
    errorType,
    errorList,
    domainRowKey,
    validatedDomainName
  };
}
export function getValidateSubdomainPickerRowSucceededAction({
  domainRowKey,
  validatedDomainName,
  isApex
}) {
  return {
    type: ActionTypes.VALIDATE_SUBDOMAIN_PICKER_ROW_SUCCEEDED,
    domainRowKey,
    validatedDomainName,
    isApex
  };
}

// Validates + updates a single domain picker row
export function validateSubdomainPickerRow(domainRowKey, domainName) {
  return dispatch => {
    dispatch(getValidateSubdomainPickerRowAttemptedAction());
    Domains.validateDomain(domainName).then(domain => {
      return dispatch(getValidateSubdomainPickerRowSucceededAction({
        domainRowKey,
        validatedDomainName: domainName,
        isApex: domain.get('isApex')
      }));
    }, response => {
      return dispatch(getValidateSubdomainPickerRowFailedAction({
        errorType: getDomainValidationErrorType(response.responseJSON),
        errorList: getDomainValidationErrorList(response.responseJSON),
        domainRowKey,
        validatedDomainName: domainName
      }));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getFetchDomainLanguageAttemptedAction(domainRowKey) {
  return {
    type: ActionTypes.FETCH_DOMAIN_LANGUAGE_ATTEMPTED,
    domainRowKey
  };
}
export function getFetchDomainLanguageSucceededAction(domainRowKey, domainLanguage) {
  return {
    type: ActionTypes.FETCH_DOMAIN_LANGUAGE_SUCCEEDED,
    domainRowKey,
    domainLanguage
  };
}
export function getFetchDomainLanguageFailedAction(domainRowKey) {
  return {
    type: ActionTypes.FETCH_DOMAIN_LANGUAGE_FAILED,
    domainRowKey
  };
}
const getLanguageFromResponse = (response, suffix) => response.getIn([suffix, 'language']) || DEFAULT_DOMAINS_PRIMARY_LANGUAGE;
export function fetchDomainLanguage(domainRowKey, suffix) {
  return dispatch => {
    dispatch(getFetchDomainLanguageAttemptedAction(domainRowKey));
    Domains.fetchDomainLanguage(suffix).then(response => {
      dispatch(getFetchDomainLanguageSucceededAction(domainRowKey, getLanguageFromResponse(response, suffix)));
    }, () => {
      dispatch(getFetchDomainLanguageFailedAction(domainRowKey));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function resetDomainWizard() {
  return {
    type: ActionTypes.RESET_DOMAIN_WIZARD
  };
}
export function getAddDomainsAttemptedAction() {
  return {
    type: ActionTypes.ADD_DOMAINS_ATTEMPTED
  };
}
export function getAddDomainsSucceededAction(resp) {
  const {
    newDomains,
    newDomainIds
  } = getNewDomainsAndNewDomainIdsFromResponse(resp);
  const {
    dnsRecords
  } = getDomainsListAndDnsRecordsFromTask(resp);
  return {
    type: ActionTypes.ADD_DOMAINS_SUCCEEDED,
    setupTaskCategory: resp.get('category'),
    newDomainIds,
    newDomains,
    taskId: resp.get('id').toString(),
    dnsRecords
  };
}
export function getAddDomainsFailedAction() {
  return {
    type: ActionTypes.ADD_DOMAINS_FAILED
  };
}

/**
 * Creates a setup task when users are connecting domains
 * Users can resume this task via Continue Banner
 */
export function addDomains(domainObj) {
  return (dispatch, getState) => {
    const state = getState();
    const isReverseProxySetup = !selectUseBuiltInCdn(state);
    const {
      enabled: automaticRedirectEnabled,
      subdomain: automaticRedirectSubdomain
    } = getAutomaticRedirect(state);
    dispatch(getAddDomainsAttemptedAction());
    Domains.addDomains(domainObj, {
      isReverseProxySetup
    }).then(resp => {
      const automaticRedirectSubdomainTracking = calculateTrackingSubdomainValue(automaticRedirectEnabled, automaticRedirectSubdomain);
      UsageTracker.track('connectDomainInteraction', {
        action: 'added-domains',
        category: domainObj.category,
        taskId: resp.get('id').toString(),
        isApex: resp.get('domains').some(isApexDomain),
        isReverseProxySetup,
        automaticRedirectSubdomain: automaticRedirectSubdomainTracking
      });
      dispatch(getAddDomainsSucceededAction(resp));
      if (resp.get('category') === DomainSetupTaskCategories.BRAND_REPLACE) {
        dispatch(populateReplaceBrandsFieldsFromSetupTask(resp));
      }
      const newBrandName = getBrandNameFromAddDomainResponse(getBrands(getState()), resp);
      if (newBrandName && domainObj.category !== DomainType.REDIRECT) {
        dispatch(getAddBrandRequestSucceededAction(newBrandName));
      }
    }, resp => {
      FloatingAlertStore.addAlert(getAddDomainFailedNotification(getDomainValidationErrorType(resp.responseJSON), getNumDomainsInAddDomainPayload(domainObj)));
      dispatch(getAddDomainsFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getAddDomainFailedAfterPurchaseAction() {
  return {
    type: ActionTypes.ADD_DOMAIN_AFTER_PURCHASE_FAILED
  };
}
export function addContentHostingDomainsAndApplyTemplate(domainObj) {
  return dispatch => {
    dispatch(getAddDomainsAttemptedAction());
    Domains.addDomains(domainObj).then(resp => {
      dispatch(getAddDomainsSucceededAction(resp));
      afterDelayApplyContentHostingDomainConnectTemplate(resp.get('domains').map(domain => getDomainIdAsString(domain)), resp.get('id').toString(), dispatch);
    }, () => {
      dispatch(getAddDomainFailedAfterPurchaseAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function updateVerificationEndingTime(verificationEndingTime) {
  return {
    type: ActionTypes.UPDATE_VERIFICATION_ENDING_TIME,
    verificationEndingTime
  };
}
export function getFetchTaskDomainsAttemptedAction() {
  return {
    type: ActionTypes.FETCH_TASK_DOMAINS_ATTEMPTED
  };
}
export function getFetchTaskDomainsSucceededAction(response) {
  const {
    newDomains,
    newDomainIds
  } = getNewDomainsAndNewDomainIdsFromResponse(response);
  const {
    dnsRecords
  } = getDomainsListAndDnsRecordsFromTask(response);
  return {
    type: ActionTypes.FETCH_TASK_DOMAINS_SUCCEEDED,
    taskId: response.get('id').toString(),
    domains: newDomains,
    dnsRecords,
    dnsSetupState: response.get('state'),
    newDomainIds,
    setupTaskCategory: response.get('category'),
    useBuiltInCdn: !response.get('inProgressReverseProxyTask')
  };
}

// Fills out DomainWizard fields needed for Replace Brands swap API call
export function populateReplaceBrandsFieldsFromSetupTask(response) {
  return {
    type: ActionTypes.POPULATE_REPLACE_BRANDS_FIELDS_FROM_SETUP_TASK,
    brandToReplace: response.get('oldBrand'),
    replacementBrand: response.get('newBrand'),
    domainReplacements: getDomainReplacementMap(response.get('domains')),
    isConnectingReplacementBrand: true
  };
}

/**
 * Populates DomainWizard state from setup task
 *
 * Note: A given taskId may have multiple domainIds if the customer was connecting multiple content types
 * Note: If no domainId is provided, default to the first domain
 **/
export function fetchDomainsInTask(match, taskId, domainId) {
  return dispatch => {
    dispatch(getVerifyTaskDomainsAttemptedAction());
    const result = Domains.fetchTaskDomainsVerificationInfo(taskId).then(resp => {
      const verificationEndingTime = resp.get('nextCheckAt');
      dispatch(updateVerificationEndingTime(verificationEndingTime));
      const {
        domainsList,
        dnsRecords,
        reverseProxyValidationResponses
      } = getDomainsListAndDnsRecordsFromTask(resp);
      dispatch(getVerifyTaskDomainsSucceededAction(domainsList, dnsRecords, reverseProxyValidationResponses));
      if (!domainsList.length) {
        FloatingAlertStore.addAlert(getTaskDomainsFetchSuccessNoDomainsFound());
        navigateToDomainListing(match);
      } else {
        const domainInTask = domainId ? domainsList.find(domain => getDomainIdAsString(domain) === domainId) : domainsList.first();

        /** Report error to sentry for better debugging */
        // https://sentry.hubteam.com/sentry/settings-ui-domains-6/issues/17976150/
        // TODO: delete once sentry is resolved
        if (domainInTask === undefined) {
          Raven.captureMessage('domain in task is undefined. App would have crashed', {
            domainsList,
            domainIdNeeded: domainId
          });
        }
        /** end report error to sentry */

        // Early return if nothing else to do here
        if (!domainInTask || domainInTask.get('isSetupComplete')) {
          return;
        }

        /**
         * Note that no API calls are made for fetching task domains because we have all the information we need from the verify response
         * The somewhat redundant action dispatch calls will be cleaned up
         */
        dispatch(getFetchTaskDomainsAttemptedAction());
        dispatch(getFetchTaskDomainsSucceededAction(resp));
        if (resp.get('category') === DomainSetupTaskCategories.BRAND_REPLACE) {
          dispatch(populateReplaceBrandsFieldsFromSetupTask(resp));
        }
        const apexDomain = resp.getIn(['domains', 0, 'apexDomain']);
        if (!apexDomain) {
          // Nothing more to do but set the failed state if we don't have the apexDomain
          dispatch(getDnsProviderFailedAction());
          return;
        }
        dispatch(getDnsProviderAttemptedAction());
        Domains.fetchDnsProvider(apexDomain).then(dnsProviderResp => dispatch(getDnsProviderSucceededAction(dnsProviderResp))).catch(() => {
          Raven.captureMessage('Call to fetchDNSPRovider failed', {
            domainId,
            domainInTask,
            taskId
          });
          dispatch(getDnsProviderFailedAction());
        });
      }
    }).catch(err => {
      Raven.captureMessage('Verify task domains failed', {
        domainId,
        taskId
      });
      dispatch(getVerifyTaskDomainsFailedAction(getCustomError(err)));
    });
    result.catch(err => {
      Raven.captureException(err, {
        tags: {
          action: ActionTypes.FETCH_VERIFY_TASK_DOMAINS_FAILED
        }
      });
    });
    return result;
  };
}

/**
 * Dispatch this action to attempt adding a new domain usage type
 *
 * Reducer will take care of preventing duplicates
 */
export function addSetupDomainUsageType(newSetupDomainUsageType) {
  return {
    type: ActionTypes.ADD_SETUP_DOMAIN_USAGE_TYPE,
    newSetupDomainUsageType
  };
}

/**
 * Dispatch this action to attempt removing a new domain usage type
 *
 * Reducer will take care of safe removal
 */
export function removeSetupDomainUsageType(removedSetupDomainUsageType) {
  return {
    type: ActionTypes.REMOVE_SETUP_DOMAIN_USAGE_TYPE,
    removedSetupDomainUsageType
  };
}

/**
 * @deprecated - purely used to store DedicatedIP account type
 *               delete this and store that in DedicatedIP v2 slice instead
 */
export function updateDomainContentType(contentTypes) {
  return {
    type: ActionTypes.UPDATE_CONTENT_TYPE,
    contentTypes
  };
}
export function updateSubdomainString(domainRowKey, subdomain) {
  return {
    type: ActionTypes.UPDATE_SUBDOMAIN_STRING,
    subdomain,
    domainRowKey
  };
}
export function updateSubdomainPrimaryLanguage(domainRowKey, domainLanguage) {
  return {
    type: ActionTypes.UPDATE_SUBDOMAIN_PRIMARY_LANGUAGE,
    domainLanguage,
    domainRowKey
  };
}
export function updateSuffixString(domainRowKey, suffix) {
  return {
    type: ActionTypes.UPDATE_SUFFIX_STRING,
    suffix,
    domainRowKey
  };
}
export function updateAllSubdomainPickerStrings(subdomain) {
  return {
    type: ActionTypes.UPDATE_ALL_SUBDOMAIN_STRINGS,
    subdomain
  };
}
export function updateAllSuffixPickerStrings(suffix) {
  return {
    type: ActionTypes.UPDATE_ALL_SUFFIX_STRINGS,
    suffix
  };
}
export function updateRedirectTarget(redirectTarget) {
  return {
    type: ActionTypes.UPDATE_REDIRECT_TARGET,
    redirectTarget
  };
}
export function updateDomainName(inputtedDomainNameOrEmailAddress) {
  return {
    type: ActionTypes.UPDATE_DOMAIN_NAME,
    inputtedDomainNameOrEmailAddress
  };
}
export function updateFinalStepScreenAction(finalStepScreen) {
  return {
    type: ActionTypes.UPDATE_FINAL_STEP_SCREEN,
    finalStepScreen
  };
}
export function enableDidUtilizeDomainConnect() {
  return {
    type: ActionTypes.ENABLE_DID_UTILIZE_DOMAIN_CONNECT
  };
}
export function changeToDomainConnectLoading() {
  return (dispatch, getState) => {
    const state = getState();
    const dnsProvider = getDnsProvider(state);
    UsageTracker.track('domainConnectInteraction', {
      action: 'domain-connect-success',
      'dns-provider': dnsProvider
    });
    dispatch(updateFinalStepScreenAction(AddDomainWizardFinalStepScreens.SHORT_LOADING));
    dispatch(enableDidUtilizeDomainConnect());
  };
}
export function selectBrandToReplace(brandName, oldDomainNames) {
  return {
    type: ActionTypes.BRAND_TO_REPLACE_SELECTED,
    brandToReplace: brandName,
    oldDomainNames
  };
}
export function selectReplacementBrand(brandName, isConnectingReplacementBrand = false) {
  return {
    type: ActionTypes.NEW_BRAND_SELECTED,
    replacementBrand: brandName,
    isConnectingReplacementBrand
  };
}
export function getReplaceBrandPendingAction() {
  return {
    type: ActionTypes.REPLACE_BRAND_ATTEMPTED
  };
}
export function getReplaceBrandSucceededAction(brands) {
  return {
    type: ActionTypes.REPLACE_BRAND_SUCCEEDED,
    brands
  };
}
export function getReplaceBrandFailedAction() {
  return {
    type: ActionTypes.REPLACE_BRAND_FAILED
  };
}
export function replaceBrand(brandToReplace, replacementBrand, domainReplacements, showingInternalDomains, businessUnitId) {
  return dispatch => {
    dispatch(getReplaceBrandPendingAction());
    return Domains.replaceBrand(brandToReplace, replacementBrand, domainReplacements).then(brands => {
      if (businessUnitId === 0 || businessUnitId) {
        dispatch(getReplaceBrandSucceededAction());
        dispatch(fetchDashboardByBusinessUnit(businessUnitId, {
          includeInternal: showingInternalDomains
        }));
        dispatch(fetchBrandsByBusinessUnit(businessUnitId));
      } else {
        dispatch(getReplaceBrandSucceededAction(brands));
        dispatch(fetchDashboard({
          includeInternal: showingInternalDomains
        }));
      }
    }, () => {
      FloatingAlertStore.addAlert(getAddBrandFailureNotification(replacementBrand));
      dispatch(getReplaceBrandFailedAction());
    }).catch(err => {
      throw err;
    });
  };
}