import { UsageTypeToDomainUsageTypesMap } from '../../Constants';
import { getPrimaryUsageTypes } from './UsageTypes';

/**
 * If trying to add more primaries:
 * - Cannot add existing primary
 * - Can replace existing secondary (so we ignore them)
 *
 * If trying to add more secondaries:
 * - Cannot add existing primary
 * - Cannot add existing secondary
 */
export function getStagedDomainUsageTypesByDomainType(domainObj, isAddingPrimary = false) {
  return isAddingPrimary ? getPrimaryUsageTypes(domainObj).map(usageType => UsageTypeToDomainUsageTypesMap[usageType]) : domainObj.get('usageTypes').map(usageType => UsageTypeToDomainUsageTypesMap[usageType]);
}