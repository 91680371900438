/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-confusing-browser-globals */

'use es6';

import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { DomainConnectMessages, ConnectTypes, DnsProviders } from './Constants';
import { openWindow } from './shared';
export function getAppendToFinal(portalId, domainId) {
  return `/${portalId}?domainId=${domainId}`;
}
export function getDnsProviderUrlParam(dnsProvider) {
  switch (dnsProvider) {
    case DnsProviders.ONE_AND_ONE:
      return 'ionos';
    case DnsProviders.GODADDY:
    default:
      return 'godaddyconnect';
  }
}
export function getDomainConnectUrl(domainId, rootDomain, hostsList, dnsProvider) {
  const portalId = PortalIdParser.get();
  const hostname = getFullUrl('app');
  const appendToFinal = encodeURIComponent(getAppendToFinal(portalId, domainId));
  const dnsProviderUrlParam = getDnsProviderUrlParam(dnsProvider);
  let baseUrl = `${hostname}/oauthor/v1/${dnsProviderUrlParam}/start/cos-domains?domain=${rootDomain}&portalId=${portalId}&appendToFinal=${appendToFinal}`;

  // Note: we intentionally append hosts separate from "appendToFinal" to avoid encoding them
  if (hostsList) {
    hostsList.forEach(host => {
      baseUrl = `${baseUrl}&host=${host}`;
    });
  }
  return baseUrl;
}
function getIsMessageFromCorrectWindow(event, domainId) {
  const hostname = window.location.hostname;
  if (event.origin !== `https://${hostname}`) {
    return false;
  }
  const {
    type,
    payload
  } = event.data;
  if (type !== ConnectTypes.HS_DOMAIN_CONNECT_ASYNC || payload.domainId !== domainId) {
    return false;
  }
  return true;
}
export function connectDomain(domainId, rootDomain, hostsList, dnsProvider) {
  openWindow(getDomainConnectUrl(domainId, rootDomain, hostsList, dnsProvider));
  return new Promise((resolve, reject) => {
    const receiveMessage = event => {
      if (!getIsMessageFromCorrectWindow(event, domainId)) {
        return;
      }
      const {
        payload
      } = event.data;
      switch (payload.message) {
        case DomainConnectMessages.SUCCEEDED:
          resolve(payload.domainId);
          break;
        case DomainConnectMessages.FAILED:
        default:
          reject(payload.domainId);
      }
    };
    window.addEventListener('message', receiveMessage, false);
  });
}
export function getDomainPurchaseAndConnectUrl(domainId, dnsProvider) {
  const portalId = PortalIdParser.get();
  const hostname = getFullUrl('app');
  const appendToFinal = encodeURIComponent(getAppendToFinal(portalId, domainId));
  const dnsProviderUrlParam = getDnsProviderUrlParam(dnsProvider);
  return `${hostname}/oauthor/v1/${dnsProviderUrlParam}/start/cos-domains?portalId=${portalId}&appendToFinal=${appendToFinal}&hosts=www`;
}
export function purchaseAndConnectDomain(domainId, dnsProvider) {
  openWindow(getDomainPurchaseAndConnectUrl(domainId, dnsProvider));
  return new Promise((resolve, reject) => {
    const receiveMessage = event => {
      if (!getIsMessageFromCorrectWindow(event, domainId)) {
        return;
      }
      const {
        payload
      } = event.data;
      switch (payload.message) {
        case DomainConnectMessages.SUCCEEDED:
          resolve(payload.domainName);
          break;
        case DomainConnectMessages.FAILED:
        default:
          reject(payload.domainName);
      }
    };
    window.addEventListener('message', receiveMessage, false);
  });
}