import { createSelector } from 'reselect';
import { getDomainsSuffixes as getDomainsSuffixesMap, getSubdomainNames as getSubdomainNamesMap, getSetupDomainUsageTypes as getSetupDomainUsageTypesList } from './DomainWizard';
export const getDomainsSuffixes = createSelector([getDomainsSuffixesMap], domainSuffixesMap => {
  return domainSuffixesMap.toObject();
});
export const getSubdomainNames = createSelector([getSubdomainNamesMap], domainSuffixesMap => {
  return domainSuffixesMap.toObject();
});
export const getSetupDomainUsageTypes = createSelector([getSetupDomainUsageTypesList], setupDomainUsageTypes => {
  return setupDomainUsageTypes.toArray();
});
const getReverseProxyValidationResponses = state => state.domainWizard.get('reverseProxyValidationResponses').toArray();
const getPassingReverseProxyValidationResponses = createSelector([getReverseProxyValidationResponses], reverseProxyValidationResponses => {
  if (reverseProxyValidationResponses.length === 0) {
    return [];
  }
  return reverseProxyValidationResponses.map(response => response.get('passingChecks').toArray()).flat().map(check => check.toJS());
});
export const getFailingReverseProxyValidationResponses = createSelector([getReverseProxyValidationResponses], reverseProxyValidationResponses => {
  if (reverseProxyValidationResponses.length === 0) {
    return [];
  }
  return reverseProxyValidationResponses.map(response => response.get('failedChecks').toArray()).flat().map(check => check.toJS());
});
export const getFlattenedSortedReverseProxyChecks = createSelector([getFailingReverseProxyValidationResponses, getPassingReverseProxyValidationResponses], (failingReverseProxyValidationChecks, passingReverseProxyValidationChecks) => {
  return failingReverseProxyValidationChecks.concat(passingReverseProxyValidationChecks);
});