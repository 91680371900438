import { List } from 'immutable';
import { createSelector } from 'reselect';
import { getDnsRecords } from './DomainWizard';
/**
 * @deprecated - convert to util when moving to v2 DNS endpoints
 */
export const getNumberOfInvalidDedicatedIPDNSRecords = createSelector([getDnsRecords], dnsRecords => {
  if (!dnsRecords.size) {
    return null;
  }
  return dnsRecords.reduce((invalidCount, dnsRecord) => invalidCount + dnsRecord.get('conflictingRecords', List()).size + dnsRecord.get('records', List()).filter(recordEntry => !recordEntry.get('isValid')).size, 0);
});