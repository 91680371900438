'use es6';

import Immutable from 'immutable';
import { NonHttpHeaderSecuritySettings, ComplexSecuritySettings } from '../../Constants';
import * as SecurityHeaders from '../../enums/domains/SecurityHeaders';
import * as SecuritySettingCategories from '../../enums/domains/SecuritySettingCategories';
import { LegacyTlsOptions, DEFAULT as DEFAULT_TLS_VERSION } from '../../enums/domains/TlsOptions';
export const getFormattedNewSecuritySettingsList = stagedSettings => {
  return stagedSettings.filter((securitySetting, securitySettingName) => {
    return !!securitySetting && (Immutable.Map.isMap(securitySetting) && securitySetting.get('value') !== undefined || !Immutable.Map.isMap(securitySetting)) && !NonHttpHeaderSecuritySettings.includes(securitySettingName) && Object.keys(SecurityHeaders).includes(securitySettingName);
  }).map((securitySettingValue, securitySettingName) => {
    const isComplexSecuritySetting = ComplexSecuritySettings.includes(securitySettingName);
    let value = Immutable.Map.isMap(securitySettingValue) ? securitySettingValue.get('value') : securitySettingValue;
    try {
      value = JSON.stringify(isComplexSecuritySetting ? value : {
        value
      });
    } catch (e) {
      console.error(securitySettingName, e);
    }
    return Immutable.Map({
      subCategory: securitySettingName,
      category: SecuritySettingCategories.HTTP_HEADER,
      value
    });
  }).toList();
};
export const getSettingsIdsToDelete = (stagedSettings, originalSettings) => {
  return originalSettings.filter(setting => stagedSettings.get(setting.get('subCategory')) === undefined).map(setting => setting.get('id'));
};
export const getFormattedTlsVersionValue = tlsVersion => LegacyTlsOptions.includes(tlsVersion) ? tlsVersion.replace('+', '') : DEFAULT_TLS_VERSION.replace('+', '');
export default function getUpdateDomainSettingsNetworkPayload(stagedSettings, originalSettings) {
  return Immutable.Map({
    isSslOnly: stagedSettings.get(SecurityHeaders.HTTPS) || false,
    minimumTlsVersion: getFormattedTlsVersionValue(stagedSettings.getIn([SecurityHeaders.TLS, 'value']) || DEFAULT_TLS_VERSION),
    domainSettings: getFormattedNewSecuritySettingsList(stagedSettings),
    deletedDomainSettingIds: getSettingsIdsToDelete(stagedSettings, originalSettings)
  });
}