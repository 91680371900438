import keyMirror from 'react-utils/keyMirror';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import allSettled from 'hs-promise-utils/allSettled';
import DedicatedIPAPI from '../../api/domains/DedicatedIP';
import DedicatedIPAPIv2 from '../../api/DedicatedIPv2';
import * as ActionTypes from '../ActionTypes';
// @ts-expect-error not migrated to TS
import { checkDnsProvider } from './Domains';
// @ts-expect-error not migrated to TS
import { updateDomainContentType } from './DomainWizard';
import { getDedicatedIPSetupAccountType } from '../../selectors/domains/DomainWizard';
import { getDedicatedIPStatusMapByAccountType, getDedicatedMarketingStatusMap, getDedicatedTransactionalStatusMap, getPortalCanSetupMoreMarketingIPs, getPortalCanSetupMoreTransactionalIPs } from '../../selectors/DedicatedIPv2';
import { fetchAllDedicatedIPStatuses, fetchDedicatedIPNeedsAccountSetup } from '../../slices/DedicatedIPv2';

// @ts-expect-error not migrated to TS
import { getI18nApiWarning } from '../../utils/domains/langUtils';
import { concatAndSortDNSRecords, normalizeDedicatedIpDnsRecords, getSmtpDomain, getIsStatusSetUp, getVerifyDnsRecordsApiClient } from '../../utils/domains/dedicatedIP';
import { DedicatedIPAccountTypes, DedicatedIPMarketingSetupTypes, DedicatedIPTransactionalSetupTypes } from '../../enums/DedicatedIP';
const VALIDATION_ERRORS = keyMirror({
  ACCOUNT_SETUP_ALREADY_EXISTS: null,
  DISALLOWED_DOMAIN: null
});
export const getAddDedicatedIPAttemptedAction = () => ({
  type: ActionTypes.ADD_DEDICATED_IP_ATTEMPTED
});
export const getAddDedicatedIPSucceededAction = domainName => ({
  type: ActionTypes.ADD_DEDICATED_IP_SUCCEEDED,
  newDedicatedIPRootDomain: domainName
});
export const getAddDedicatedIPFailedAction = () => ({
  type: ActionTypes.ADD_DEDICATED_IP_FAILED
});
export const getFetchDNSRecordsAttemptedAction = () => ({
  type: ActionTypes.FETCH_DEDICATED_IP_DNS_RECORDS_ATTEMPTED
});
export const getFetchDNSRecordsSucceededAction = dnsRecords => ({
  type: ActionTypes.FETCH_DEDICATED_IP_DNS_RECORDS_SUCCEEDED,
  dnsRecords
});
export const getFetchDNSRecordsFailedAction = () => ({
  type: ActionTypes.FETCH_DEDICATED_IP_DNS_RECORDS_FAILED
});
export const getVerifyDNSRecordsAttemptedAction = () => ({
  type: ActionTypes.VERIFY_DEDICATED_IP_DNS_RECORDS_ATTEMPTED
});
export const getVerifyDNSRecordsSucceededAction = dnsRecords => ({
  type: ActionTypes.VERIFY_DEDICATED_IP_DNS_RECORDS_SUCCEEDED,
  dnsRecords
});
export const getVerifyDNSRecordsFailedAction = () => ({
  type: ActionTypes.VERIFY_DEDICATED_IP_DNS_RECORDS_FAILED
});
// TODO: move to wizard useEffect(), v2 thunk addDedicatedIP.rejected
const handleAddDedicatedIPErrors = (error = {}, accountType, domainName) => (dispatch, getState) => {
  const titleText = getI18nApiWarning(`addDedicatedIP.failed.title`);
  const smtpDomain = getSmtpDomain({
    accountType,
    domainName
  });
  switch (error.subCategory) {
    case VALIDATION_ERRORS.ACCOUNT_SETUP_ALREADY_EXISTS:
      return dispatch(fetchAllDedicatedIPStatuses({
        accountType
      })).then(() => {
        const statusMap = getDedicatedIPStatusMapByAccountType(accountType)(getState());
        if (getIsStatusSetUp(statusMap[smtpDomain])) {
          FloatingAlertStore.addAlert({
            message: getI18nApiWarning(`addDedicatedIP.failed.message.alreadySetUp.${accountType}`, {
              domain: smtpDomain
            }),
            titleText,
            type: 'danger'
          });
        } else {
          FloatingAlertStore.addAlert({
            message: getI18nApiWarning(`addDedicatedIP.failed.message.differentDomainExists.${accountType}`, {
              domain: smtpDomain
            }),
            titleText,
            type: 'danger'
          });
        }
        throw error;
      });
    case VALIDATION_ERRORS.DISALLOWED_DOMAIN:
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('addDedicatedIP.failed.message.disallowedDomain'),
        titleText,
        type: 'danger'
      });
      throw error;
    default:
      FloatingAlertStore.addAlert({
        message: getI18nApiWarning('addDedicatedIP.failed.message.default'),
        titleText,
        type: 'danger'
      });
      throw error;
  }
};
const addAndUpdateDedicatedIPType = (accountType, domainName) => dispatch => DedicatedIPAPI.addDedicatedIP(accountType, domainName).then(() => {
  // TODO: addDedicatedIP.fulfilled can store new status in state
  //       can remove this fetch all to save 1 API call per create
  void dispatch(fetchAllDedicatedIPStatuses({
    accountType
  }));
}, error => dispatch(handleAddDedicatedIPErrors(error.responseJSON, accountType, domainName)));

/**
 * @deprecated - move to Dedicated IP v2 thunk
 */
export const addDedicatedIP = (domainName, closeWizard) => (dispatch, getState) => {
  dispatch(getAddDedicatedIPAttemptedAction());
  const setupAccountTypeFromWizard = getDedicatedIPSetupAccountType(getState());
  const canSetupMoreMarketingIPs = getPortalCanSetupMoreMarketingIPs(getState());
  const canSetupMoreTransactionalIPs = getPortalCanSetupMoreTransactionalIPs(getState());
  const shouldAddMarketing = canSetupMoreMarketingIPs && setupAccountTypeFromWizard in DedicatedIPMarketingSetupTypes;
  const shouldAddTransactional = canSetupMoreTransactionalIPs && setupAccountTypeFromWizard in DedicatedIPTransactionalSetupTypes;
  let addMarketingPromise = Promise.resolve();
  let addTransactionalPromise = Promise.resolve();
  if (shouldAddMarketing) {
    addMarketingPromise = dispatch(addAndUpdateDedicatedIPType(DedicatedIPAccountTypes.DEDICATED_MARKETING, domainName));
  }
  if (shouldAddTransactional) {
    addTransactionalPromise = dispatch(addAndUpdateDedicatedIPType(DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL, domainName));
  }
  return allSettled([addMarketingPromise, addTransactionalPromise]).then(responses => {
    const allSucceeded = responses.reduce((acc, response) => acc && response.status === 'fulfilled', true);
    if (allSucceeded) {
      dispatch(getAddDedicatedIPSucceededAction(domainName));
      // Re-fetch IP limits from BE
      void dispatch(fetchDedicatedIPNeedsAccountSetup());
    } else {
      const shouldCloseWizard = responses.reduce((acc, response) => acc || response.status === 'rejected' && response.reason.subCategory === VALIDATION_ERRORS.ACCOUNT_SETUP_ALREADY_EXISTS, false);
      if (shouldCloseWizard) {
        closeWizard();
      } else {
        dispatch(getAddDedicatedIPFailedAction());
      }
    }
    return allSucceeded;
  });
};

/**
 * @deprecated - move to Dedicated IP v2 thunk
 */
export const fetchDedicatedIPDNSRecords = domainName => (dispatch, getState) => {
  const dedicatedIPSetupAccountType = getDedicatedIPSetupAccountType(getState());
  const shouldFetchMarketing = (dedicatedIPSetupAccountType in DedicatedIPMarketingSetupTypes);
  const shouldFetchTransactional = (dedicatedIPSetupAccountType in DedicatedIPTransactionalSetupTypes);
  dispatch(getFetchDNSRecordsAttemptedAction());
  return Promise.all([shouldFetchMarketing ? DedicatedIPAPIv2.fetchDedicatedIPDNSRecords({
    accountType: DedicatedIPAccountTypes.DEDICATED_MARKETING,
    smtpDomain: getSmtpDomain({
      accountType: DedicatedIPAccountTypes.DEDICATED_MARKETING,
      domainName
    })
  }) : Promise.resolve(), shouldFetchTransactional ? DedicatedIPAPIv2.fetchDedicatedIPDNSRecords({
    accountType: DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL,
    smtpDomain: getSmtpDomain({
      accountType: DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL,
      domainName
    })
  }) : Promise.resolve()]).then(dnsRecordResponses => {
    dispatch(getFetchDNSRecordsSucceededAction(normalizeDedicatedIpDnsRecords(concatAndSortDNSRecords(dnsRecordResponses))));
  }, () => {
    FloatingAlertStore.addAlert({
      message: getI18nApiWarning('fetchDedicatedIPDNSRecords.failed.message'),
      type: 'danger'
    });
    return dispatch(getFetchDNSRecordsFailedAction());
  });
};

/**
 * @deprecated - move to Dedicated IP v2 thunk
 */
export const verifyDedicatedIPDNSRecords = domainName => (dispatch, getState) => {
  const dedicatedIPSetupAccountType = getDedicatedIPSetupAccountType(getState());
  const shouldVerifyMarketing = (dedicatedIPSetupAccountType in DedicatedIPMarketingSetupTypes);
  const shouldVerifyTransactional = (dedicatedIPSetupAccountType in DedicatedIPTransactionalSetupTypes);
  const dedicatedMarketingStatusMap = getDedicatedMarketingStatusMap(getState());
  const dedicatedTransactionalStatusMap = getDedicatedTransactionalStatusMap(getState());
  dispatch(getVerifyDNSRecordsAttemptedAction());
  return Promise.all([shouldVerifyMarketing ? getVerifyDnsRecordsApiClient({
    accountType: DedicatedIPAccountTypes.DEDICATED_MARKETING,
    smtpDomain: getSmtpDomain({
      accountType: DedicatedIPAccountTypes.DEDICATED_MARKETING,
      domainName
    }),
    statusMap: dedicatedMarketingStatusMap
  }) : Promise.resolve(), shouldVerifyTransactional ? getVerifyDnsRecordsApiClient({
    accountType: DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL,
    smtpDomain: getSmtpDomain({
      accountType: DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL,
      domainName
    }),
    statusMap: dedicatedTransactionalStatusMap
  }) : Promise.resolve()]).then(dnsRecordResponses => {
    dispatch(getVerifyDNSRecordsSucceededAction(normalizeDedicatedIpDnsRecords(concatAndSortDNSRecords(dnsRecordResponses))));
  }, () => {
    FloatingAlertStore.addAlert({
      message: getI18nApiWarning('fetchDedicatedIPDNSRecords.failed.message'),
      type: 'danger'
    });
    return dispatch(getVerifyDNSRecordsFailedAction());
  });
};

/**
 * @deprecated - move to Dedicated IP v2 thunk
 */
export const continueDedicatedIPSetup = (dedicatedIPSetupAccountType, domainName) => dispatch => {
  dispatch(updateDomainContentType({
    dedicatedIPSetupAccountType
  }));
  const shouldFetchMarketing = (dedicatedIPSetupAccountType in DedicatedIPMarketingSetupTypes);
  const shouldFetchTransactional = (dedicatedIPSetupAccountType in DedicatedIPTransactionalSetupTypes);
  Promise.all([shouldFetchMarketing ? dispatch(fetchAllDedicatedIPStatuses({
    accountType: DedicatedIPAccountTypes.DEDICATED_MARKETING
  })) : Promise.resolve(), shouldFetchTransactional ? dispatch(fetchAllDedicatedIPStatuses({
    accountType: DedicatedIPAccountTypes.DEDICATED_TRANSACTIONAL
  })) : Promise.resolve()]).then(() => dispatch(checkDnsProvider(domainName))).catch(err => {
    throw err;
  });
  void dispatch(fetchDedicatedIPDNSRecords(domainName));
};