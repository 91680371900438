export const FILES_HUBFS = 'FILES_HUBFS';
export const FILES_HS_FS = 'FILES_HS_FS';
export const STATIC_ASSETS = 'STATIC_ASSETS';
export const SCRIPT_LOADER = 'SCRIPT_LOADER';
export const CTA_LINKS = 'CTA_LINKS';
export const EVENT_TRACKING_LINKS = 'EVENT_TRACKING_LINKS';
export const CMS_PAGES_STATUS = 'CMS_PAGES_STATUS';
export const CMS_PAGES_DIAGNOSTICS = 'CMS_PAGES_DIAGNOSTICS';
export const CMS_PAGES_EDGE = 'CMS_PAGES_EDGE';

// gated check
export const DELEGATED_DCV = 'DELEGATED_DCV';
const ReverseProxyCheckTypeEnum = {
  DELEGATED_DCV,
  FILES_HUBFS,
  FILES_HS_FS,
  STATIC_ASSETS,
  SCRIPT_LOADER,
  CTA_LINKS,
  EVENT_TRACKING_LINKS,
  CMS_PAGES_STATUS,
  CMS_PAGES_DIAGNOSTICS,
  CMS_PAGES_EDGE
};
export default ReverseProxyCheckTypeEnum;