import I18n from 'I18n';
import formatRelativeDuration from 'I18n/utils/formatRelativeDuration';
export const getRelativeTimeFromTimestampUserTz = timestamp => {
  const msToEvent = I18n.moment.userTz(timestamp).diff(I18n.moment.userTz());
  return formatRelativeDuration(I18n.moment.duration(msToEvent, 'milliseconds'));
};
export const isTimestampInLast15Minutes = timestamp => {
  if (timestamp === undefined) {
    return false;
  }
  const fifteentMinsAgo = I18n.moment().subtract(15, 'minutes');
  return I18n.moment(timestamp).isAfter(fifteentMinsAgo);
};