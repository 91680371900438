'use es6';

import http from 'hub-http/clients/apiClient';
import { fromJS } from 'immutable';
const DOWNGRADE_NOTIFICATIONS_API_PATH = 'cos-domains/v1/domain-management/notifications';
export function fetchDowngradeNotifications() {
  return http.get(DOWNGRADE_NOTIFICATIONS_API_PATH).then(fromJS);
}
export function dismissDowngradeNotification(downgradeId) {
  return http.post(`${DOWNGRADE_NOTIFICATIONS_API_PATH}/dismiss/${downgradeId}`).then(fromJS);
}